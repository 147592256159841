import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SettingsIcon from "@mui/icons-material/Settings";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import GroupIcon from "@mui/icons-material/Group";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import BadgeIcon from "@mui/icons-material/Badge";
import EmojiObjectsIcon from "@mui/icons-material/EmojiObjects";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import LogoutIcon from "@mui/icons-material/Logout";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Avatar } from "@mui/material";
import ListIcon from "@mui/icons-material/List";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";

import { deslogar } from "../../store/modules/users/actions";

export default function MenuMobile({ state, setState }) {
  const userData = useSelector((state) => state.users);
  const location = useLocation();
  const dispatch = useDispatch();

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleLogout = () => {
    dispatch(deslogar());
    localStorage.removeItem("user");
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 280 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar
            sx={{
              width: 55,
              height: 55,
              marginTop: 1,
              marginBottom: 1,
            }}
            alt="Foto usuário"
            src={userData.avatar}
          />

          <h1
            style={{
              margin: 0,
              fontSize: "18px",
              color: "#383838",
              paddingBottom: 15,
            }}
          >
            {userData.name}
          </h1>
        </div>

        <Divider />

        <Link to="/dashboard">
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon
                sx={{
                  minWidth: "40px",
                  color:
                    location.pathname === "/dashboard" ? "#3FC2DA" : "#383838",
                }}
              >
                <DashboardIcon />
              </ListItemIcon>

              <ListItemText
                sx={{
                  width: "max-content",
                  color:
                    location.pathname === "/dashboard" ? "#3FC2DA" : "#383838",
                }}
                primary={"Dashboard"}
              />
            </ListItemButton>
          </ListItem>
        </Link>

        {userData?.permissions?.includes("Parâmetros de contrato") && (
          <Link to="/parametros-contrato">
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon
                  sx={{
                    minWidth: "40px",
                    color:
                      location.pathname === "/parametros-contrato"
                        ? "#3FC2DA"
                        : "#383838",
                  }}
                >
                  <SettingsIcon />
                </ListItemIcon>

                <ListItemText
                  sx={{
                    width: "max-content",
                    color:
                      location.pathname === "/parametros-contrato"
                        ? "#3FC2DA"
                        : "#383838",
                  }}
                  primary={"Parâmetros de contratos"}
                />
              </ListItemButton>
            </ListItem>
          </Link>
        )}

        {userData?.permissions?.includes("Adicionar contrato") && (
          <Link to="/adicionar-contrato">
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon
                  sx={{
                    minWidth: "40px",
                    color:
                      location.pathname === "/adicionar-contrato"
                        ? "#3FC2DA"
                        : "#383838",
                  }}
                >
                  <HistoryEduIcon />
                </ListItemIcon>

                <ListItemText
                  sx={{
                    width: "max-content",
                    color:
                      location.pathname === "/adicionar-contrato"
                        ? "#3FC2DA"
                        : "#383838",
                  }}
                  primary={"Adicionar contrato"}
                />
              </ListItemButton>
            </ListItem>
          </Link>
        )}

        {userData?.permissions?.includes("PLD") && (
          <Link to="/plds">
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon
                  sx={{
                    minWidth: "40px",
                    color:
                      location.pathname === "/plds" ? "#3FC2DA" : "#383838",
                  }}
                >
                  <CurrencyExchangeIcon />
                </ListItemIcon>

                <ListItemText
                  sx={{
                    width: "max-content",
                    color:
                      location.pathname === "/plds" ? "#3FC2DA" : "#383838",
                  }}
                  primary={"PLDs"}
                />
              </ListItemButton>
            </ListItem>
          </Link>
        )}

        {userData?.permissions?.includes("Atualização por Período") && (
          <Link to="/atualizacao-periodo">
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon
                  sx={{
                    minWidth: "40px",
                    color:
                      location.pathname === "/atualizacao-periodo"
                        ? "#3FC2DA"
                        : "#383838",
                  }}
                >
                  <EventRepeatIcon />
                </ListItemIcon>

                <ListItemText
                  sx={{
                    width: "max-content",
                    color:
                      location.pathname === "/atualizacao-periodo"
                        ? "#3FC2DA"
                        : "#383838",
                  }}
                  primary={"Atualização por Período"}
                />
              </ListItemButton>
            </ListItem>
          </Link>
        )}

        {userData?.permissions?.includes("Índices") && (
          <Link to="/indices">
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon
                  sx={{
                    minWidth: "40px",
                    color:
                      location.pathname === "/indices" ? "#3FC2DA" : "#383838",
                  }}
                >
                  <ListIcon />
                </ListItemIcon>

                <ListItemText
                  sx={{
                    width: "max-content",
                    color:
                      location.pathname === "/indices" ? "#3FC2DA" : "#383838",
                  }}
                  primary={"Índices"}
                />
              </ListItemButton>
            </ListItem>
          </Link>
        )}
      </List>

      {["Tranches", "Empresas"].some((o) =>
        userData.permissions.includes(o)
      ) && <Divider />}

      <List>
        {userData?.permissions?.includes("Tranches") && (
          <Link to="/tranches">
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon
                  sx={{
                    minWidth: "40px",
                    color:
                      location.pathname === "/tranches" ? "#3FC2DA" : "#383838",
                  }}
                >
                  <EmojiObjectsIcon />
                </ListItemIcon>

                <ListItemText
                  sx={{
                    width: "max-content",
                    color:
                      location.pathname === "/tranches" ? "#3FC2DA" : "#383838",
                  }}
                  primary={"Tranches"}
                />
              </ListItemButton>
            </ListItem>
          </Link>
        )}

        {userData?.permissions?.includes("Empresas") && (
          <Link to="/empresas">
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon
                  sx={{
                    minWidth: "40px",
                    color:
                      location.pathname === "/empresas" ? "#3FC2DA" : "#383838",
                  }}
                >
                  <SettingsIcon />
                </ListItemIcon>

                <ListItemText
                  sx={{
                    width: "max-content",
                    color:
                      location.pathname === "/empresas" ? "#3FC2DA" : "#383838",
                  }}
                  primary={"Empresas"}
                />
              </ListItemButton>
            </ListItem>
          </Link>
        )}
      </List>

      {["Usuários", "Perfis de acesso", "Credenciais CCEE"].some((o) =>
        userData.permissions.includes(o)
      ) && <Divider />}

      <List>
        {userData?.permissions?.includes("Usuários") && (
          <Link to="/usuarios">
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon
                  sx={{
                    minWidth: "40px",
                    color:
                      location.pathname === "/usuarios" ? "#3FC2DA" : "#383838",
                  }}
                >
                  <GroupIcon />
                </ListItemIcon>

                <ListItemText
                  sx={{
                    width: "max-content",
                    color:
                      location.pathname === "/usuarios" ? "#3FC2DA" : "#383838",
                  }}
                  primary={"Usuários"}
                />
              </ListItemButton>
            </ListItem>
          </Link>
        )}

        {userData?.permissions?.includes("Perfis de acesso") && (
          <Link to="/perfis-acesso">
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon
                  sx={{
                    minWidth: "40px",
                    color:
                      location.pathname === "/perfis-acesso"
                        ? "#3FC2DA"
                        : "#383838",
                  }}
                >
                  <AdminPanelSettingsIcon />
                </ListItemIcon>

                <ListItemText
                  sx={{
                    width: "max-content",
                    color:
                      location.pathname === "/perfis-acesso"
                        ? "#3FC2DA"
                        : "#383838",
                  }}
                  primary={"Perfis de acesso"}
                />
              </ListItemButton>
            </ListItem>
          </Link>
        )}

        {userData?.permissions?.includes("Credenciais CCEE") && (
          <Link to="/ccee">
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon
                  sx={{
                    minWidth: "40px",
                    color:
                      location.pathname === "/ccee" ? "#3FC2DA" : "#383838",
                  }}
                >
                  <BadgeIcon />
                </ListItemIcon>

                <ListItemText
                  sx={{
                    width: "max-content",
                    color:
                      location.pathname === "/ccee" ? "#3FC2DA" : "#383838",
                  }}
                  primary={"Credenciais CCEE"}
                />
              </ListItemButton>
            </ListItem>
          </Link>
        )}
      </List>

      {[
        "Parâmetros do sistema",
        "Gestão de feriados",
        "Parâmetros do Protheus",
      ].some((o) => userData?.permissions?.includes(o)) && <Divider />}

      <List>
        {userData?.permissions?.includes("Parâmetros do sistemas") && (
          <Link to="/parametros-sistema">
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon
                  sx={{
                    minWidth: "40px",
                    color:
                      location.pathname === "/parametros-sistema"
                        ? "#3FC2DA"
                        : "#383838",
                  }}
                >
                  <SettingsIcon />
                </ListItemIcon>

                <ListItemText
                  sx={{
                    width: "max-content",
                    color:
                      location.pathname === "/parametros-sistema"
                        ? "#3FC2DA"
                        : "#383838",
                  }}
                  primary={"Parâmetros do sistemas"}
                />
              </ListItemButton>
            </ListItem>
          </Link>
        )}

        {userData?.permissions?.includes("Gestão de feriados") && (
          <Link to="/gestao-feriados">
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon
                  sx={{
                    minWidth: "40px",
                    color:
                      location.pathname === "/gestao-feriados"
                        ? "#3FC2DA"
                        : "#383838",
                  }}
                >
                  <CalendarMonthIcon />
                </ListItemIcon>

                <ListItemText
                  sx={{
                    width: "max-content",
                    color:
                      location.pathname === "/gestao-feriados"
                        ? "#3FC2DA"
                        : "#383838",
                  }}
                  primary={"Gestão de feriados"}
                />
              </ListItemButton>
            </ListItem>
          </Link>
        )}

        {userData?.permissions?.includes("Parâmetros Protheus") && (
          <Link to="/parametros-protheus">
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon
                  sx={{
                    minWidth: "40px",
                    color:
                      location.pathname === "/parametros-protheus"
                        ? "#3FC2DA"
                        : "#383838",
                  }}
                >
                  <SettingsIcon />
                </ListItemIcon>

                <ListItemText
                  sx={{
                    width: "max-content",
                    color:
                      location.pathname === "/parametros-protheus"
                        ? "#3FC2DA"
                        : "#383838",
                  }}
                  primary={"Parâmetros Protheus"}
                />
              </ListItemButton>
            </ListItem>
          </Link>
        )}

        <Divider />

        <ListItem onClick={() => handleLogout()} disablePadding>
          <ListItemButton>
            <ListItemIcon sx={{ minWidth: "40px", color: "#383838" }}>
              <LogoutIcon />
            </ListItemIcon>

            <ListItemText
              sx={{ width: "max-content", color: "#383838" }}
              primary={"Sair"}
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <SwipeableDrawer
      anchor={"right"}
      open={state["right"]}
      onClose={toggleDrawer("right", false)}
      onOpen={toggleDrawer("right", true)}
    >
      {list("right")}
    </SwipeableDrawer>
  );
}
