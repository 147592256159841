import styled from "styled-components";

export const Area = styled.section` 
    padding: 1em;
    background-color: #F7F7F7;
    height: 100%;
    min-height: 100vh;

    @media (max-width: 468px){
        padding: 0;
        margin-bottom: 7em;
    }
`;

export const Title = styled.h1` 
    font-weight: 700;
    font-size: 26px;
    line-height: 24px;
    color: #000000;
    margin-top: 1em;
    margin-bottom: .5em;

    @media (max-width: 468px){
        margin-top: 0;
        font-size: 22px;
        padding-top: 1.5em;
        padding-left: .5em;
    }
`;