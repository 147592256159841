import { useDispatch } from "react-redux";

import MainModal from "../../../../../components/MainModal";
import api from "../../../../../services/api";
import { ModalButtonArea } from "./styled";
import { loadingFullActive } from "../../../../../store/modules/loadingFull/action";
import { snackbarActive } from "../../../../../store/modules/snackbar/action";

export default function ModalConfirmarLimpezaModulacao({
  open,
  setOpen,
  contratoId,
  apiGetDataContractShow,
}) {
  const dispatch = useDispatch();

  const apiLimpezaModulacao = async () => {
    dispatch(loadingFullActive(true));

    setOpen(false);

    await api
      .delete(`/modulacao/${contratoId}`)
      .then((response) => {
        apiGetDataContractShow();

        dispatch(
          snackbarActive({
            type: "success",
            message:
              response?.data?.message ||
              "Limpeza de modulação realizada com sucesso!",
          })
        );
      })
      .catch((error) => {
        dispatch(
          snackbarActive({
            type: "error",
            message:
              error?.response?.data?.message || "Tente novamente mais tarde!",
          })
        );
      })
      .finally(() => {
        dispatch(loadingFullActive(false));
      });
  };

  return (
    <>
      <MainModal width={450} open={open} setOpen={setOpen}>
        <h2 style={{ margin: 0 }}>Confirmação</h2>

        <p style={{ marginTop: "8px", marginBottom: 0 }}>
          Deseja confirmar a remoção de modulação desse subcontrato?
        </p>

        <ModalButtonArea>
          <button onClick={() => apiLimpezaModulacao()}>
            Confirmar remoção
          </button>
          <button onClick={() => setOpen(false)}>Cancelar</button>
        </ModalButtonArea>
      </MainModal>
    </>
  );
}
