import { InputPattern } from "./styled";

export default function Input(props) {
  return (
    <InputPattern
      sx={{
        "& label.Mui-focused": {
          color: "var(--main-color)",
        },
        "& .MuiInput-underline:after": {
          borderBottomColor: "var(--main-color)",
        },
        "& .MuiOutlinedInput-root": {
          "&:hover fieldset": {
            borderColor: "var(--main-color)",
          },
          "&.Mui-focused fieldset": {
            borderColor: "var(--main-color)",
          },
        },
      }}
      fullWidth
      {...props}
      variant="outlined"
    />
  );
}
