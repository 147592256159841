import { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Link } from "react-router-dom";
import { Avatar, IconButton, Menu, MenuItem } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useSelector, useDispatch } from "react-redux";

import {
  Area,
  Fixed,
  LogoArea,
  InfoArea,
  AreaMenu,
  MobileArea,
  LeftSide,
} from "./styled";
import MenuMobile from "../MenuMobile";
import { deslogar } from "../../store/modules/users/actions";
import { setMenuMinimized } from "../../store/modules/configs/actions";

export default function Header() {
  const [state, setState] = useState({ right: false });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const user = useSelector((state) => state.users);
  const isMenuMinimized = useSelector((state) => state.configs);
  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(deslogar());
    localStorage.removeItem("user");
  };

  const handleMenuMinimized = () => {
    dispatch(setMenuMinimized(!isMenuMinimized));
  };

  return (
    <Area>
      <Fixed>
        <LeftSide>
          <IconButton
            className="buttonMenuMinimized"
            onClick={() => handleMenuMinimized()}
            style={{ width: "50px", marginLeft: "-.3em" }}
          >
            <MenuIcon />
          </IconButton>

          <LogoArea>
            <Link to="/dashboard">
              <img
                src="/images/logo.png"
                alt="Logo Ibitu Energia"
                width="100"
                height="45"
              />
            </Link>
          </LogoArea>
        </LeftSide>

        <InfoArea>
          <Avatar
            sx={{ marginRight: "1em" }}
            alt="Foto usuário"
            src={user.avatar}
          />
          <div>
            <AreaMenu
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <h6>{user.name}</h6>
              <KeyboardArrowDownIcon />
            </AreaMenu>
            <Menu
              disableScrollLock={true}
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              PaperProps={{
                style: {
                  maxHeight: 45 * 4.5,
                  width: "13ch",
                },
              }}
            >
              <MenuItem
                onClick={() => {
                  handleClose();
                  handleLogout();
                }}
              >
                Sair
              </MenuItem>
            </Menu>
          </div>
        </InfoArea>

        <MobileArea>
          <IconButton onClick={() => setState({ right: true })}>
            <MenuIcon />
          </IconButton>
        </MobileArea>
      </Fixed>

      <MenuMobile state={state} setState={setState} />
    </Area>
  );
}
