import { useEffect } from "react";
import { Grid } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import CachedIcon from "@mui/icons-material/Cached";

import Input from "../../../components/Input";
import Label from "../../../components/Label";
import MainModal from "../../../components/MainModal";
import api from "../../../services/api";
import { ModalButtonArea, Title } from "./styled";
import { loadingFullActive } from "../../../store/modules/loadingFull/action";
import { snackbarActive } from "../../../store/modules/snackbar/action";

export default function ModalReajusteValor({
  open,
  setOpen,
  apiGetDataIndices,
  contrato,
}) {
  const {
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();

  const apiCreateIndice = async (data) => {
    dispatch(loadingFullActive(true));

    const newIndice = {
      indice: data.indice.replace(",", "."),
      inicio_suprimento: contrato["Inicio suprimento"],
      fim_suprimento: contrato["Fim suprimento"],
    };

    await api
      .post(`/reajustes/${contrato.id}`, newIndice)
      .then((response) => {
        dispatch(
          snackbarActive({
            type: "success",
            message:
              response?.data?.message || "Contrato reajustado com sucesso!",
          })
        );

        apiGetDataIndices();

        reset();

        setOpen(false);
      })
      .catch((error) => {
        dispatch(
          snackbarActive({
            type: "error",
            message:
              error?.response?.data?.message || "Tente novamente mais tarde!",
          })
        );
      })
      .finally(() => {
        dispatch(loadingFullActive(false));
      });
  };

  const apiGetValores = async () => {
    dispatch(loadingFullActive(true));

    await api
      .get(`/reajustes/${contrato.id}/calcular`, {
        params: {
          indice: watch("indice")?.replace(",", ".") || null,
        },
      })
      .then((response) => {
        setValue(
          "valorReajustado",
          String(response.data?.preco_corrigido)?.replace(".", ",")
        );
        setValue(
          "precoBaseReajuste",
          String(response.data?.preco_original)?.replace(".", ",")
        );
        setValue(
          "indice",
          String(response.data?.indice || "")?.replace(".", ",")
        );
      })
      .catch((error) => {
        dispatch(
          snackbarActive({
            type: "error",
            message:
              error?.response?.data?.message || "Tente novamente mais tarde!",
          })
        );
      })
      .finally(() => {
        dispatch(loadingFullActive(false));
      });
  };

  useEffect(() => {
    reset();

    if (open) {
      apiGetValores();
    }
  }, [open]);

  return (
    <>
      <MainModal width={600} open={open} setOpen={setOpen}>
        <Title>
          Reajuste do contrato <b>{contrato["Código do contrato"]}</b> por{" "}
          <b>{contrato["Índice de reajuste"]}</b>
        </Title>

        <form onSubmit={handleSubmit(apiCreateIndice)}>
          <Grid mt={1} container spacing={2}>
            <Grid item md={4} xs={12}>
              <Label text="Preço base de reajuste:" />

              <Controller
                rules={{ required: true }}
                control={control}
                name="precoBaseReajuste"
                defaultValue={""}
                render={({ field: { value, onChange, onBlur }, ref }) => (
                  <NumberFormat
                    thousandSeparator="."
                    decimalScale={2}
                    allowNegative={false}
                    decimalSeparator=","
                    fixedDecimalScale
                    type="text"
                    getInputRef={ref}
                    disabled
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={errors.precoBaseReajuste ? true : false}
                    fullWidth
                    placeholder="R$ 00,00"
                    customInput={Input}
                  />
                )}
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <Label text="Índice de reajuste" />

              <Controller
                rules={{ required: true }}
                control={control}
                name="indice"
                defaultValue={""}
                render={({ field: { value, onChange, onBlur }, ref }) => (
                  <Input
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    ref={ref}
                    error={Boolean(errors.indice)}
                    fullWidth
                    placeholder="Índice de reajuste"
                  />
                )}
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <Label text="Valor reajustado" />

              <Controller
                rules={{ required: true }}
                control={control}
                name="valorReajustado"
                defaultValue={""}
                render={({ field: { value, onChange, onBlur }, ref }) => (
                  <NumberFormat
                    thousandSeparator="."
                    decimalScale={2}
                    allowNegative={false}
                    decimalSeparator=","
                    fixedDecimalScale
                    type="text"
                    getInputRef={ref}
                    disabled
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={errors.valorReajustado ? true : false}
                    fullWidth
                    placeholder="R$ 00,00"
                    customInput={Input}
                  />
                )}
              />
            </Grid>
          </Grid>

          <ModalButtonArea>
            <button type="button" onClick={() => apiGetValores()}>
              Recalcular
              <CachedIcon fontSize="small" />
            </button>

            <button type="submit">Reajustar contrato</button>

            <button type="button" onClick={() => setOpen(false)}>
              Cancelar reajuste
            </button>
          </ModalButtonArea>
        </form>
      </MainModal>
    </>
  );
}
