import { useEffect, useState } from "react";
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Pagination,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import FeedIcon from "@mui/icons-material/Feed";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import { useDispatch } from "react-redux";
import NumberFormat from "react-number-format";

import BigModal from "../../../../components/BigModal";
import Input from "../../../../components/Input";
import Label from "../../../../components/Label";
import SelectInput from "../../../../components/SelectInput";
import {
  MessageError,
  ModalButtonArea,
  InfoArea,
  CodeArea,
  DataArea,
  ActionArea,
  LabelOptions,
  AreaValidity,
  BtnAction,
} from "./styled";
import api from "../../../../services/api";
import Table from "../../../../components/Table";
import ModalConfirmarLimpezaModulacao from "./ModalConfirmarLimpezaModulacao";
import { loadingFullActive } from "../../../../store/modules/loadingFull/action";
import { snackbarActive } from "../../../../store/modules/snackbar/action";

export default function ModalEditContract({
  open,
  setOpen,
  contractId,
  selects,
  getDataApiContracts,
}) {
  const dispatch = useDispatch();

  const [volumeMWh, setVolumeMWh] = useState("");

  const [optionApplyContract, setOptionApplyContract] = useState("atual");

  const [contentSelects, setContentSelects] = useState({});

  const [
    openModalConfirmarLimpezaModulacao,
    setOpenModalConfirmarLimpezaModulacao,
  ] = useState(false);

  const [contract, setContract] = useState({});

  const [modulacao, setModulacao] = useState([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);

  const {
    handleSubmit,
    watch,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const getDataApiSelects = async () => {
    await api
      .get("/contracts/to-edit")
      .then((response) => {
        setContentSelects(response.data);
      })
      .catch((error) => {
        dispatch(
          snackbarActive({
            type: "error",
            message:
              error?.response?.data?.message || "Tente novamente mais tarde!",
          })
        );
      })
      .finally(() => {});
  };

  const apiEditContract = async (data) => {
    dispatch(loadingFullActive(true));

    const editData = {
      razoes_negocio_id: data.razaoNegocio,
      cod_contrato_cliqccee: data.contratoCliq,
      loja_excecao: data.lojaFornecedor,
      contratos_gerados: "",
      cod_contrato_fisico: data.numeroContrato,
      tipos_operacao_id: data.tipoOperacao,
      montante_mwm: data?.volumeMWm?.replaceAll(".", "").replace(",", "."),
      montante_mwh: volumeMWh?.replaceAll(".", "").replace(",", "."),
      ressarcimento: data?.ressarcimento?.replaceAll(".", "").replace(",", "."),
      vencimento_dias_id: data.vencimentoDia,
      vencimento_tipos_id: data.vencimentoTipo,
      indice_reajuste_id: data.indiceReajuste,
      preco_energia_atual: data?.precoEnergia
        ?.replaceAll(".", "")
        .replace(",", "."),
      cod_bbce: data.contratoBBCE,
      alterar_sequencia: optionApplyContract,
      titulo: data.titulo?.trim(),
      centros_custos_id: data.centroCusto,
      classes_valor_id: data.classeValor,
      mensagem_nota_id: data.mensagemNota,
    };

    await api
      .put(`/contracts/${contract.id}/update`, editData)
      .then((response) => {
        getDataApiContracts();

        setOpen(false);

        dispatch(
          snackbarActive({
            type: "success",
            message:
              response?.data?.message || "Contrato alterado com sucesso!",
          })
        );

        setOptionApplyContract("atual");
      })
      .catch((error) => {
        dispatch(
          snackbarActive({
            type: "error",
            message:
              error?.response?.data?.message || "Tente novamente mais tarde!",
          })
        );
      })
      .finally(() => {
        dispatch(loadingFullActive(false));
      });
  };

  const apiGetDataContractShow = async () => {
    dispatch(loadingFullActive(true));

    await api
      .get(`/contracts/${contractId}/show`)
      .then((response) => {
        setContract(response.data);
        setValue("razaoNegocio", response.data.razoes_negocio_id || "");
        setValue("lojaFornecedor", response.data.loja_excecao || "");
        setValue("numeroContrato", response.data.cod_contrato_fisico || "");
        setValue("contratoBBCE", response.data.cod_bbce || "");
        setValue("contratoCliq", response.data.cod_contrato_cliqccee || "");
        setValue("tipoOperacao", response.data.tipos_operacao_id || "");
        setValue(
          "volumeMWm",
          response.data.montante_mwm.replace(".", ",") || ""
        );
        setValue("horas", response.data.horas || "");
        setValue(
          "ressarcimento",
          response.data.ressarcimento
            ? response.data.ressarcimento.replace(".", ",")
            : ""
        );
        setValue("classeValor", response.data.classes_valor_id || "");
        setValue("indiceReajuste", response.data.indice_reajuste_id || "");
        setValue(
          "precoEnergia",
          response.data.preco_energia_atual
            ? response.data.preco_energia_atual.replace(".", ",")
            : ""
        );
        setValue("vencimentoDia", response.data.vencimento_dias_id || "");
        setValue("vencimentoTipo", response.data.vencimento_tipos_id || "");
        setValue("centroCusto", response.data.centros_custos_id || "");
        setValue("produtoVenda", response.data.produtos_venda_id || "");
        setValue("produtoCompra", response.data.produtos_compra_id || "");
        setValue("tes", response.data.tes_id || "");
        setVolumeMWh(response.data.montante_mwh || "");
        setValue("titulo", response.data.titulo || "");
        setValue("mensagemNota", response.data.mensagem_nota_id || "");

        if (response.data.modular) {
          apiModulacao();
        }
      })
      .catch((error) => {
        dispatch(
          snackbarActive({
            type: "error",
            message:
              error?.response?.data?.message || "Tente novamente mais tarde!",
          })
        );
      })
      .finally(() => {
        dispatch(loadingFullActive(false));
      });
  };

  const apiModulacao = async () => {
    dispatch(loadingFullActive(true));

    setModulacao([]);

    await api
      .get(`/modulacao/${contractId}`, {
        params: { page: page },
      })
      .then((response) => {
        setModulacao(response.data.data);
        setLastPage(response.data?.last_page);
      })
      .catch((error) => {
        dispatch(
          snackbarActive({
            type: "error",
            message:
              error?.response?.data?.message || "Tente novamente mais tarde!",
          })
        );
      })
      .finally(() => {
        dispatch(loadingFullActive(false));
      });
  };

  useEffect(() => {
    if (watch("volumeMWm") && watch("horas")) {
      let volumeMwm = String(watch("volumeMWm"))
        .replaceAll(".", "")
        .replace(",", ".");
      let horas = String(watch("horas"));

      let resultado = parseFloat(volumeMwm) * Number(horas);

      resultado = Math.ceil(resultado * Math.pow(10, 3)) / Math.pow(10, 3);

      setVolumeMWh(String(resultado).replace(".", ","));
    }
  }, [watch("volumeMWm"), watch("horas")]);

  useEffect(() => {
    getDataApiSelects();
  }, []);

  useEffect(() => {
    if (open) {
      if (contract?.modular) {
        apiModulacao();
      }
    }
  }, [page]);

  useEffect(() => {
    if (open) {
      apiGetDataContractShow();
    }
  }, [open]);

  return (
    <>
      <BigModal open={open} setOpen={setOpen}>
        <div
          style={{
            marginBottom: 7,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <InfoArea
            style={{ display: "flex", flexDirection: "row", gap: "10px" }}
          >
            <p title="ponta A">{contract["ponta-a"]}</p>
            <p title="fato do Contrato">{contract["fato-contrato"]}</p>
            <p title="ponta B">{contract["ponta-b"]}</p>
          </InfoArea>

          <CodeArea style={{ textAlign: "center" }}>
            <h5>Cod. Interno</h5>
            <p>{contract["codigo_inter_tranche"]}</p>
          </CodeArea>

          <DataArea>
            <p>
              Vigência:&nbsp;
              <span>
                {contract.data_vigencia_inicial} à{" "}
                {contract.data_vigencia_final}
              </span>
            </p>
          </DataArea>
        </div>

        <Divider />

        <div
          style={{ marginTop: 5, display: "flex", justifyContent: "flex-end" }}
        >
          <ActionArea>
            <div>
              <StickyNote2Icon />
            </div>
            <p>Editar garantia</p>
          </ActionArea>

          <ActionArea>
            <div>
              <FeedIcon />
            </div>
            <p>Gerar minuta</p>
          </ActionArea>
        </div>

        <Grid mt={1} container spacing={2}>
          <Grid item md={4}>
            <Label text="Título do contrato" />

            <Controller
              name="titulo"
              control={control}
              rules={{ required: false }}
              defaultValue=""
              render={({ field: { value, onChange, onBlur } }) => (
                <Input
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={Boolean(errors?.titulo)}
                  helperText={errors?.titulo?.message}
                  placeholder="Não possui"
                  fullWidth
                />
              )}
            />
          </Grid>

          <Grid item md={3}>
            <Label text="Razão do negócio" />

            <Controller
              name="razaoNegocio"
              control={control}
              rules={{ required: true }}
              defaultValue=""
              render={({ field: { value, onChange, onBlur } }) => (
                <SelectInput
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={Boolean(errors?.razaoNegocio)}
                  fullWidth
                  displayEmpty
                >
                  <MenuItem value="">
                    <em>Selecione</em>
                  </MenuItem>

                  {selects?.contentSelectRazaoNegocio?.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </SelectInput>
              )}
            />

            {errors?.razaoNegocio && (
              <MessageError>Campo necessário</MessageError>
            )}
          </Grid>

          <Grid item md={2}>
            <Label text="Loja do fornecedor" />

            <Controller
              name="lojaFornecedor"
              control={control}
              rules={{ required: false }}
              defaultValue=""
              render={({ field: { value, onChange, onBlur } }) => (
                <Input
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={Boolean(errors?.lojaFornecedor)}
                  helperText={errors?.lojaFornecedor?.message}
                  placeholder="Não possui"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid mt={1} mb={3} container spacing={2}>
          <Grid item md={3}>
            <Label text="Número contrato físico" />

            <Controller
              name="numeroContrato"
              control={control}
              rules={{ required: false }}
              defaultValue=""
              render={({ field: { value, onChange, onBlur } }) => (
                <Input
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={Boolean(errors?.numeroContrato)}
                  helperText={errors?.numeroContrato?.message}
                  placeholder="Não possui"
                  fullWidth
                />
              )}
            />
          </Grid>

          <Grid item md={3}>
            <Label text="Contrato BBCE" />

            <Controller
              name="contratoBBCE"
              control={control}
              rules={{ required: false }}
              defaultValue=""
              render={({ field: { value, onChange, onBlur } }) => (
                <Input
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={Boolean(errors?.contratoBBCE)}
                  helperText={errors?.contratoBBCE?.message}
                  placeholder="Não possui"
                  fullWidth
                />
              )}
            />
          </Grid>

          <Grid item md={3}>
            <Label text="Contrato CliqCCEE" />

            <Controller
              name="contratoCliq"
              control={control}
              rules={{ required: false }}
              defaultValue=""
              render={({ field: { value, onChange, onBlur } }) => (
                <Input
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={Boolean(errors?.contratoCliq)}
                  helperText={errors?.contratoCliq?.message}
                  placeholder="Não possui"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>

        <Divider />

        <Grid mt={1} container spacing={2}>
          <Grid item md={3}>
            <Label text="Tipo de operação" />

            <Controller
              name="tipoOperacao"
              control={control}
              rules={{ required: true }}
              defaultValue=""
              render={({ field: { value, onChange, onBlur } }) => (
                <SelectInput
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={Boolean(errors?.tipoOperacao)}
                  fullWidth
                  displayEmpty
                >
                  <MenuItem value="">
                    <em>Selecione</em>
                  </MenuItem>

                  {selects?.contentSelectTipoOperacao?.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </SelectInput>
              )}
            />

            {errors?.tipoOperacao && (
              <MessageError>Campo necessário</MessageError>
            )}
          </Grid>

          <Grid item md={2}>
            <Label text="Volume MWm" />

            <Controller
              name="volumeMWm"
              control={control}
              rules={{ required: true }}
              defaultValue={0}
              render={({ field, ref }) => (
                <NumberFormat
                  thousandSeparator="."
                  decimalScale={6}
                  allowNegative={false}
                  decimalSeparator=","
                  fixedDecimalScale
                  type="text"
                  ref={ref}
                  {...field}
                  error={errors.volumeMWm ? true : false}
                  fullWidth
                  placeholder="00,00"
                  customInput={Input}
                />
              )}
            />

            {errors.volumeMWm && <MessageError>Campo necessário</MessageError>}
          </Grid>

          <Grid item md={2}>
            <Label text="Volume MWh" />

            <NumberFormat
              thousandSeparator="."
              decimalScale={3}
              allowNegative={false}
              decimalSeparator=","
              fixedDecimalScale
              type="text"
              value={volumeMWh}
              disabled={true}
              error={errors.volumeMWh ? true : false}
              fullWidth
              placeholder="00,00"
              customInput={Input}
            />
          </Grid>

          <Grid item md={1}>
            <Label text="Horas" />

            <Controller
              name="horas"
              control={control}
              rules={{ required: "Campo necessário" }}
              defaultValue=""
              render={({ field: { value, onChange, onBlur } }) => (
                <Input
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  disabled={true}
                  error={Boolean(errors?.horas)}
                  helperText={errors?.horas?.message}
                  placeholder="Não possui"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid my={1} container spacing={2}>
          <Grid item md={2}>
            <Label text="Ressarcimento TUSD" />

            <Controller
              name="ressarcimento"
              control={control}
              rules={{ required: false }}
              defaultValue=""
              render={({ field, ref }) => (
                <NumberFormat
                  thousandSeparator="."
                  decimalScale={2}
                  allowNegative={false}
                  decimalSeparator=","
                  fixedDecimalScale
                  type="text"
                  ref={ref}
                  {...field}
                  error={errors.ressarcimento ? true : false}
                  fullWidth
                  placeholder="00,00"
                  customInput={Input}
                />
              )}
            />

            {errors.ressarcimento && (
              <MessageError>Campo necessário</MessageError>
            )}
          </Grid>
          <Grid item md={2}>
            <Label text="Indice de reajuste" />

            <Controller
              name="indiceReajuste"
              control={control}
              rules={{ required: true }}
              defaultValue=""
              render={({ field: { value, onChange, onBlur } }) => (
                <SelectInput
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={Boolean(errors?.indiceReajuste)}
                  fullWidth
                  displayEmpty
                >
                  <MenuItem value="">
                    <em>Selecione</em>
                  </MenuItem>

                  {contentSelects["indice-reajuste"]?.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </SelectInput>
              )}
            />

            {errors.indiceReajuste && (
              <MessageError>Campo necessário</MessageError>
            )}
          </Grid>

          <Grid item md={2}>
            <Label text="Preço da energia atual" />

            <Controller
              name="precoEnergia"
              control={control}
              rules={{ required: true }}
              defaultValue=""
              render={({ field, ref }) => (
                <NumberFormat
                  thousandSeparator="."
                  decimalScale={2}
                  allowNegative={false}
                  decimalSeparator=","
                  fixedDecimalScale
                  type="text"
                  ref={ref}
                  {...field}
                  error={errors.precoEnergia ? true : false}
                  fullWidth
                  placeholder="00,00"
                  customInput={Input}
                />
              )}
            />

            {errors.precoEnergia && (
              <MessageError>Campo necessário</MessageError>
            )}
          </Grid>

          <Grid item md={3}>
            <AreaValidity>
              <Label text="Vencimento" />

              <Controller
                name="vencimentoDia"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field: { value, onChange, onBlur } }) => (
                  <SelectInput
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={Boolean(errors?.vencimentoDia)}
                    style={{ marginRight: 20 }}
                    displayEmpty
                  >
                    <MenuItem value="">
                      <em>Selecione</em>
                    </MenuItem>

                    {contentSelects["vencimento-dias"]?.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </SelectInput>
                )}
              />

              <Controller
                name="vencimentoTipo"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field: { value, onChange, onBlur } }) => (
                  <SelectInput
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={Boolean(errors?.vencimentoTipo)}
                    displayEmpty
                  >
                    <MenuItem value="">
                      <em>Selecione</em>
                    </MenuItem>

                    {contentSelects["vencimento-tipos"]?.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </SelectInput>
                )}
              />
            </AreaValidity>
          </Grid>
        </Grid>

        <Divider />

        <Grid mt={1} container spacing={2}>
          <Grid item md={2}>
            <Label text="Classe de valor" />

            <Controller
              name="classeValor"
              control={control}
              rules={{ required: true }}
              defaultValue=""
              render={({ field: { value, onChange, onBlur } }) => (
                <SelectInput
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={Boolean(errors?.classeValor)}
                  fullWidth
                  displayEmpty
                >
                  <MenuItem value="">
                    <em>Selecione</em>
                  </MenuItem>

                  {contentSelects["classes_valor"]?.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </SelectInput>
              )}
            />

            {errors.classeValor && (
              <MessageError>Campo necessário</MessageError>
            )}
          </Grid>

          <Grid item md={2}>
            <Label text="Centro de custo" />

            <Controller
              name="centroCusto"
              control={control}
              rules={{ required: true }}
              defaultValue=""
              render={({ field: { value, onChange, onBlur } }) => (
                <SelectInput
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={Boolean(errors?.centroCusto)}
                  fullWidth
                  displayEmpty
                >
                  <MenuItem value="">
                    <em>Selecione</em>
                  </MenuItem>

                  {contentSelects["centro-custo"]?.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </SelectInput>
              )}
            />

            {errors.centroCusto && (
              <MessageError>Campo necessário</MessageError>
            )}
          </Grid>

          <Grid item md={2}>
            <Label text="Produto compra" />

            <Controller
              name="produtoCompra"
              control={control}
              rules={{ required: false }}
              defaultValue=""
              render={({ field: { value, onChange, onBlur } }) => (
                <SelectInput
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={Boolean(errors?.produtoCompra)}
                  fullWidth
                  disabled={true}
                  displayEmpty
                >
                  <MenuItem value="">
                    <em>Não possui</em>
                  </MenuItem>

                  {contentSelects["produtos"]?.length > 0 &&
                    contentSelects["produtos"]?.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                </SelectInput>
              )}
            />
          </Grid>

          <Grid item md={2}>
            <Label text="Produto venda" />

            <Controller
              name="produtoVenda"
              control={control}
              rules={{ required: false }}
              defaultValue=""
              render={({ field: { value, onChange, onBlur } }) => (
                <SelectInput
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={Boolean(errors?.produtoVenda)}
                  fullWidth
                  disabled={true}
                  displayEmpty
                >
                  <MenuItem value="">
                    <em>Não possui</em>
                  </MenuItem>

                  {contentSelects["produtos"]?.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </SelectInput>
              )}
            />
          </Grid>

          <Grid item md={2}>
            <Label text="TES" />

            <Controller
              name="tes"
              control={control}
              rules={{ required: false }}
              defaultValue=""
              render={({ field: { value, onChange, onBlur } }) => (
                <SelectInput
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={Boolean(errors?.tes)}
                  fullWidth
                  disabled={true}
                  displayEmpty
                >
                  <MenuItem value="">
                    <em>Não possui</em>
                  </MenuItem>

                  {contentSelects["tes"]?.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </SelectInput>
              )}
            />

            {errors.tes && <MessageError>Campo necessário</MessageError>}
          </Grid>
        </Grid>

        <Grid mt={1} mb={3} container spacing={2}>
          <Grid item md={2}>
            <Label text="Mensagem nota" />

            <Controller
              name="mensagemNota"
              control={control}
              rules={{ required: false }}
              defaultValue=""
              render={({ field: { value, onChange, onBlur } }) => (
                <SelectInput
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={Boolean(errors?.mensagemNota)}
                  fullWidth
                  disabled={false}
                  displayEmpty
                >
                  <MenuItem value="">
                    <em>Não possui</em>
                  </MenuItem>

                  {contentSelects["mensagem-nota"]?.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </SelectInput>
              )}
            />

            {errors?.mensagemNota && (
              <MessageError>Campo necessário</MessageError>
            )}
          </Grid>
        </Grid>

        <Divider />

        <Grid mt={1} mb={1} container spacing={2}>
          <Grid item md={2}>
            <Label text="Data Base" />

            <Input
              type="date"
              value={contract.data_base || ""}
              disabled
              fullWidth
            />
          </Grid>

          <Grid item md={2}>
            <Label text="Flags" />

            <Input value={contract.flags_id || ""} disabled fullWidth />
          </Grid>

          <Grid item md={2}>
            <Label text="Fontes" />

            <Input value={contract.fontes_id || ""} disabled fullWidth />
          </Grid>

          <Grid item md={2}>
            <Label text="Interno" />

            <Input value={contract.interno || ""} disabled fullWidth />
          </Grid>
        </Grid>

        <Grid mt={1} mb={2} container spacing={2}>
          <Grid item md={2}>
            <Label text="Spread" />

            <Input
              value={contract.spread || ""}
              disabled
              fullWidth
              placeholder="Não possui spread"
            />
          </Grid>

          <Grid item md={2}>
            <Label text="Valor estimado" />

            <NumberFormat
              thousandSeparator="."
              decimalScale={2}
              allowNegative={false}
              decimalSeparator=","
              fixedDecimalScale
              type="text"
              value={
                String(contract.valor_contrato_estimado).replace(".", ",") || ""
              }
              disabled
              fullWidth
              placeholder="00,00"
              customInput={Input}
            />
          </Grid>

          <Grid item md={2}>
            <Label text="Submercados Entrega" />

            <Input
              value={contract.submercados_entrega_id || ""}
              disabled
              fullWidth
            />
          </Grid>
        </Grid>

        {contract?.modular > 0 && (
          <>
            <Divider />

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mt: 1,
                mb: 1,
              }}
            >
              <LabelOptions>Modulação</LabelOptions>

              <BtnAction
                onClick={() => setOpenModalConfirmarLimpezaModulacao(true)}
              >
                Remover modulação
              </BtnAction>
            </Box>

            <Table header={["Dia", "Hora", "Volume"]}>
              {modulacao.length > 0 &&
                modulacao.map((item, index) => (
                  <tr key={item.index}>
                    <td>{item.dia}</td>
                    <td>{item.hora}</td>
                    <td>{item.volume}</td>
                  </tr>
                ))}
            </Table>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 10,
                paddingBottom: 20,
              }}
            >
              <Pagination
                count={lastPage}
                page={page}
                onChange={(event, value) => setPage(value)}
              />
            </div>
          </>
        )}

        <div
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LabelOptions>
            COMO DESEJA QUE ESTAS ALTERAÇÕES SEJAM APLICADAS?
          </LabelOptions>

          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={optionApplyContract}
              onChange={(e) => setOptionApplyContract(e.target.value)}
            >
              <FormControlLabel
                value={"atual"}
                control={<Radio color="secondary" />}
                label="Apenas para este subcontrato"
              />
              <FormControlLabel
                value={"para-frente"}
                control={<Radio color="secondary" />}
                label="Para este e os futuros subcontratos "
              />
              <FormControlLabel
                value={"todos"}
                control={<Radio color="secondary" />}
                label="Para o contrato inteiro"
              />
            </RadioGroup>
          </FormControl>
        </div>

        <ModalButtonArea>
          <button onClick={handleSubmit(apiEditContract)}>
            Atualizar contrato
          </button>

          <button onClick={() => setOpen(false)}>Fechar</button>
        </ModalButtonArea>
      </BigModal>

      <ModalConfirmarLimpezaModulacao
        open={openModalConfirmarLimpezaModulacao}
        setOpen={setOpenModalConfirmarLimpezaModulacao}
        contratoId={contractId}
        apiGetDataContractShow={apiGetDataContractShow}
      />
    </>
  );
}
