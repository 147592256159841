import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Grid, Pagination } from "@mui/material";
import { Controller, useForm } from "react-hook-form";

import Card from "../../components/Card";
import MainTemplate from "../../components/MainTemplate";
import Table from "../../components/Table";
import Button from "../../components/Button";
import Label from "../../components/Label";
import Input from "../../components/Input";
import MainModal from "../../components/MainModal";
import { BtnAction, ModalButtonArea, MessageError, TitleList } from "./styled";
import api from "../../services/api";
import { loadingFullActive } from "../../store/modules/loadingFull/action";
import { snackbarActive } from "../../store/modules/snackbar/action";

export default function Tranches() {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [loadingTable, setLoadingTable] = useState(false);

  const [lastPage, setLastPage] = useState(1);

  const [contentTable, setContentTable] = useState([]);
  const [idTrancheEdit, setIdTrancheEdit] = useState("");
  const [codeTrancheEdit, setCodeTrancheEdit] = useState("");
  const [nameTrancheEdit, setNameTrancheEdit] = useState("");

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const headerTable = ["Código tranche", "Nome", "Ações"];

  const openModalEdit = (tranche) => {
    setIdTrancheEdit(tranche["Código tranche"]);
    setCodeTrancheEdit(tranche["Código tranche"]);
    setNameTrancheEdit(tranche["Nome"]);
    setOpen(true);
  };

  const getDataApiTranches = async (activePage) => {
    setLoadingTable(true);

    await api
      .get(`/tranches`, { params: { page: activePage || page } })
      .then((response) => {
        let list = response.data.data;

        for (let i = 0; i < list.length; i++) {
          list[i].acoes = (
            <BtnAction onClick={() => openModalEdit(list[i])}>
              Editar tranche
            </BtnAction>
          );
        }

        setContentTable(list);
        setLastPage(response.data.last_page);
      })
      .catch((error) => {
        dispatch(
          snackbarActive({
            type: "error",
            message:
              error?.response?.data?.message || "Tente novamente mais tarde!",
          })
        );
      })
      .finally(() => {
        setLoadingTable(false);
      });
  };

  const apiCreateTranche = async (data) => {
    dispatch(loadingFullActive(true));

    await api
      .post("/tranches", { name: data.name })
      .then((response) => {
        dispatch(
          snackbarActive({
            type: "success",
            message:
              response?.data?.message ||
              "O tranche foi adicionado com sucesso!",
          })
        );

        getDataApiTranches();
      })
      .catch((error) => {
        dispatch(
          snackbarActive({
            type: "error",
            message:
              error?.response?.data?.message ||
              "Não foi possível adicionar um novo tranche, tente novamente mais tarde!",
          })
        );
      })
      .finally(() => {
        dispatch(loadingFullActive(false));
        getDataApiTranches(page);
        reset();
      });
  };

  const apiUpdateTranche = async () => {
    if (nameTrancheEdit && codeTrancheEdit) {
      setOpen(false);
      dispatch(loadingFullActive(true));

      await api
        .put(`/tranches/${idTrancheEdit}`, { name: nameTrancheEdit })
        .then((response) => {
          dispatch(
            snackbarActive({
              type: "success",
              message:
                response?.data?.message || "O tranche foi editado com sucesso!",
            })
          );

          getDataApiTranches();
        })
        .catch((error) => {
          dispatch(
            snackbarActive({
              type: "error",
              message:
                error?.response?.data?.message ||
                "Não foi possível editar o tranche, tente novamente mais tarde!",
            })
          );
        })
        .finally(() => {
          dispatch(loadingFullActive(false));
          setOpen(false);
          getDataApiTranches(page);
        });
    }
  };

  const handlePagination = (page) => {
    setPage(page);
    getDataApiTranches(page);
  };

  useEffect(() => {
    getDataApiTranches();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MainTemplate title="Adicionar Tranche">
        <Card>
          <Grid container spacing={2}>
            <Grid item md={3} sm={6} xs={12}>
              <Label text="Nome da tranche" />

              <Controller
                name="name"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field, ref }) => (
                  <Input
                    {...field}
                    ref={ref}
                    error={errors.name ? true : false}
                    fullWidth
                    placeholder="Nome"
                  />
                )}
              />

              {errors.name && <MessageError>Campo necessário</MessageError>}
            </Grid>
          </Grid>

          <Button
            onClick={handleSubmit(apiCreateTranche)}
            text="Adicionar"
            sx={{ mt: 4, mb: 1 }}
          />
        </Card>

        <TitleList>Tranches</TitleList>

        <Card style={{ padding: 0 }}>
          <Table header={headerTable} loading={loadingTable}>
            {contentTable?.length > 0 &&
              contentTable?.map((item) => (
                <tr key={item["Código tranche"]}>
                  <td>{item["Código tranche"]}</td>
                  <td>{item["Nome"]}</td>
                  <td>{item["acoes"]}</td>
                </tr>
              ))}
          </Table>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 10,
              paddingBottom: 20,
            }}
          >
            <Pagination
              count={lastPage}
              page={page}
              onChange={(event, value) => handlePagination(value)}
            />
          </div>
        </Card>

        <MainModal width={450} open={open} setOpen={setOpen}>
          <h2 style={{ margin: 0 }}>Editar Tranche</h2>

          <Label sx={{ mt: 2 }} text="Nome" />
          <Input
            value={nameTrancheEdit}
            onChange={(e) => setNameTrancheEdit(e.target.value)}
            fullWidth
            placeholder="Nome"
          />

          <ModalButtonArea>
            <button onClick={() => apiUpdateTranche()}>Editar</button>
            <button onClick={() => setOpen(false)}>Cancelar</button>
          </ModalButtonArea>
        </MainModal>
      </MainTemplate>
    </>
  );
}
