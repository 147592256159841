import styled from "styled-components";
import { Button } from "@mui/material";

export const Btn = styled(Button)`
  background-color: var(--main-color) !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 25px !important;
  color: #ffffff !important;
  border-radius: 34px !important;
  padding: 8px 24px !important;
  text-transform: none !important;
  width: max-content;

  span {
    padding-right: 8px;
  }

  :hover {
    opacity: 0.6;
  }

  svg {
    font-size: 20px;
  }
`;
