import { useEffect } from "react";
import { Grid } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import Input from "../../../components/Input";
import Label from "../../../components/Label";
import MainModal from "../../../components/MainModal";
import api from "../../../services/api";
import { ModalButtonArea } from "./styled";
import { loadingFullActive } from "../../../store/modules/loadingFull/action";
import { snackbarActive } from "../../../store/modules/snackbar/action";

export default function ModalEditarIndice({
  open,
  setOpen,
  apiGetDataIndices,
  valueIndiceSelected,
}) {
  const {
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();

  const apiEditIndice = async (data) => {
    dispatch(loadingFullActive(true));

    const updateData = {
      mes_ano: data.mesAno,
      indice: data.indice.replaceAll(".", "").replaceAll(",", "."),
      acumulado_mes: data.acumuladoMes.replace(",", "."),
      acumulado_ano: data.acumuladoAno.replace(",", "."),
    };

    await api
      .put(`/indexes/values/${valueIndiceSelected?.id}/update`, updateData)
      .then((response) => {
        dispatch(
          snackbarActive({
            type: "success",
            message:
              response?.data?.message || "O índice foi atualizado com sucesso!",
          })
        );

        apiGetDataIndices();

        reset();

        setOpen(false);
      })
      .catch((error) => {
        dispatch(
          snackbarActive({
            type: "error",
            message:
              error?.response?.data?.message || "Tente novamente mais tarde!",
          })
        );
      })
      .finally(() => {
        dispatch(loadingFullActive(false));
      });
  };

  useEffect(() => {
    if (open && valueIndiceSelected.id) {
      setValue(
        "mesAno",
        `${valueIndiceSelected.mes_ano.split("/")[1]}-${
          valueIndiceSelected.mes_ano.split("/")[0]
        }`
      );
      setValue("indice", valueIndiceSelected.indice);
      setValue("acumuladoMes", valueIndiceSelected.acumulado_mes);
      setValue("acumuladoAno", valueIndiceSelected.acumulado_ano);
    }
  }, [open]);

  return (
    <>
      <MainModal width={550} open={open} setOpen={setOpen}>
        <h2 style={{ margin: 0 }}>Editar índice</h2>

        <form onSubmit={handleSubmit(apiEditIndice)}>
          <Grid mt={1} container spacing={2}>
            <Grid item md={6} xs={12}>
              <Label text="Mês e Ano" />

              <Controller
                rules={{ required: true }}
                control={control}
                name="mesAno"
                defaultValue={""}
                render={({ field: { value, onChange, onBlur }, ref }) => (
                  <Input
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    ref={ref}
                    error={Boolean(errors.mesAno)}
                    fullWidth
                    type="month"
                  />
                )}
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <Label text="Índice" />

              <Controller
                rules={{ required: true }}
                control={control}
                name="indice"
                defaultValue={""}
                render={({ field: { value, onChange, onBlur }, ref }) => (
                  <Input
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    ref={ref}
                    error={Boolean(errors.indice)}
                    fullWidth
                    placeholder="Índice"
                  />
                )}
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <Label text="Acumulado mês" />

              <Controller
                rules={{ required: true }}
                control={control}
                name="acumuladoMes"
                defaultValue={""}
                render={({ field: { value, onChange, onBlur }, ref }) => (
                  <Input
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    ref={ref}
                    error={Boolean(errors.acumuladoMes)}
                    fullWidth
                    placeholder="Acumulado mês"
                  />
                )}
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <Label text="Acumulado ano" />

              <Controller
                rules={{ required: true }}
                control={control}
                name="acumuladoAno"
                defaultValue={""}
                render={({ field: { value, onChange, onBlur }, ref }) => (
                  <Input
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    ref={ref}
                    error={Boolean(errors.acumuladoAno)}
                    fullWidth
                    placeholder="Acumulado ano"
                  />
                )}
              />
            </Grid>
          </Grid>

          <ModalButtonArea>
            <button type="submit">Atualizar índice</button>

            <button type="button" onClick={() => setOpen(false)}>
              Cancelar
            </button>
          </ModalButtonArea>
        </form>
      </MainModal>
    </>
  );
}
