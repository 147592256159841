import { Backdrop } from "@mui/material";
import Lottie from "lottie-react";

import loadingAnimation from "../../assets/lotties/loadingAnimation.json";

export default function LoadingFull({ open }) {
  return (
    <Backdrop
      sx={{
        background: "#ffffffcc",
        zIndex: (theme) => theme.zIndex.drawer + 1300,
      }}
      open={open}
      transitionDuration={{ exit: 700 }}
    >
      <div style={{ width: "100px", height: "auto" }}>
        <Lottie animationData={loadingAnimation} loop={true} />
      </div>
    </Backdrop>
  );
}
