import { useEffect, useState } from "react";
import {
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  IconButton,
} from "@mui/material";
import { useDispatch } from "react-redux";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import InfoIcon from "@mui/icons-material/Info";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import { Background, Card, Notication } from "./styled";
import Input from "../../components/Input";
import Label from "../../components/Label";
import api from "../../services/api";
import { logar } from "../../store/modules/users/actions";
import { snackbarActive } from "../../store/modules/snackbar/action";

export default function Login() {
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [stage, setStage] = useState(1);
  const [showNotification, setShowNotification] = useState(false);
  const [loading, setLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();

  const fazerLogin = async (e) => {
    e.preventDefault();

    let cont = 0;

    if (user.trim() !== "") {
      cont++;
    } else {
      dispatch(
        snackbarActive({
          type: "error",
          message: "E-mail necessário",
        })
      );
    }

    if (password.trim() !== "") {
      cont++;
    } else {
      dispatch(
        snackbarActive({
          type: "error",
          message: "Senha necessária",
        })
      );
    }

    if (cont === 2) {
      setLoading(true);

      await api
        .post("/login", { username: user, password: password })
        .then((response) => {
          api
            .get("/me", {
              headers: { Authorization: `Bearer ${response.data.token}` },
            })
            .then((anotherResponse) => {
              let dataUser = {
                token: response.data.token,
                avatar: anotherResponse.data.user.avatar,
                name: anotherResponse.data.user.name,
                id: anotherResponse.data.user.id,
                permissions: anotherResponse.data.user.permissons,
              };

              api.defaults.headers[
                "Authorization"
              ] = `Bearer ${response.data?.token}`;

              localStorage.setItem("user", JSON.stringify(dataUser));

              dispatch(logar(dataUser));
            })
            .catch((error) => {
              dispatch(
                snackbarActive({
                  type: "error",
                  message:
                    error?.response?.data?.message ||
                    "Tente novamente mais tarde!",
                })
              );
            });
        })
        .catch((error) => {
          dispatch(
            snackbarActive({
              type: "error",
              message:
                error?.response?.data?.message || "Tente novamente mais tarde!",
            })
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const loginBlock = () => {
    return (
      <>
        <form onSubmit={fazerLogin}>
          <Label text="Email" />

          <Input
            value={user}
            onChange={(e) => setUser(e.target.value)}
            type="text"
            placeholder="Digite seu email"
            inputProps={{
              inputMode: "email",
            }}
          />

          <Label sx={{ mt: 2 }} text="Senha" />

          <Input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <IconButton
                  sx={{
                    padding: "0!important",
                    background: "transparent!important",
                    color: "#333333!important",
                  }}
                  onClick={() => setShowPassword((prev) => !prev)}
                >
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              ),
            }}
            type={showPassword ? "text" : "password"}
            placeholder="Digite sua senha"
          />

          <Grid pt={2} container spacing={2}>
            <Grid item md={6}>
              <Button type="submit" sx={{ mt: 2 }}>
                <span>Acessar sistema</span>

                <ArrowForwardIcon />
              </Button>
            </Grid>

            <Grid item md={6} alignSelf="center">
              <p onClick={() => setStage(2)}>Recuperar senha</p>
            </Grid>
          </Grid>
        </form>
      </>
    );
  };

  const recoveryBlock = () => {
    return (
      <>
        <Label text="E-mail" />
        <Input fullWidth placeholder="E-mail cadastrado no sistema" />

        <Grid container spacing={2} width="35em">
          <Grid item md={6} xs={12}>
            <Button onClick={() => setStage(3)} sx={{ mt: 2 }}>
              <span>Enviar link para resetar</span>

              <ArrowForwardIcon />
            </Button>
          </Grid>
          <Grid item md={6} xs={12} alignSelf="center">
            <p onClick={() => setStage(1)}>Voltar para o login</p>
          </Grid>
        </Grid>
      </>
    );
  };

  const newPassword = () => {
    return (
      <>
        <Label text="Senha" />
        <Input type="password" placeholder="Sua nova senha" />

        <Label sx={{ mt: 2 }} text="Confirmação de senha" />
        <Input type="password" placeholder="Confirmação da sua nova senha" />

        <Grid container spacing={2}>
          <Grid item md={6}>
            <Button sx={{ mt: 2 }}>
              <span>Resetar senha</span>

              <ArrowForwardIcon />
            </Button>
          </Grid>
          <Grid item md={6} alignSelf="center">
            <p onClick={() => setStage(1)}>Voltar para o login</p>
          </Grid>
        </Grid>
      </>
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Background>
        <Card>
          {showNotification && (
            <Notication>
              <Grid container spacing={2}>
                <Grid item md={2} display="flex" justifyContent="center">
                  <InfoIcon />
                </Grid>

                <Grid item md={10}>
                  <h5>Sua senha foi alterada com sucesso</h5>
                  <p>
                    Acesse o sistema com sua senha nova, para garantir que todas
                    os seus dados estão corretoes
                  </p>
                </Grid>
              </Grid>
            </Notication>
          )}

          <Grid container spacing={6}>
            <Grid item md={8} sx={{ order: { md: 1, xs: 2 }, width: "100%" }}>
              {stage === 1 && loginBlock()}

              {stage === 2 && recoveryBlock()}

              {stage === 3 && newPassword()}
            </Grid>
            <Grid
              item
              md={4}
              sx={{ order: { md: 2, xs: 1 }, alignSelf: "center" }}
              alignSelf="center"
              justifyContent="center"
              alignItems="center"
            >
              <img
                style={{ width: "100%" }}
                src="/images/logo.png"
                alt="Logo Ibitu Energia"
              />
            </Grid>
          </Grid>
        </Card>
      </Background>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={() => setLoading(!loading)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
