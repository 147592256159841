import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Box, Fab, Grid, MenuItem, Pagination } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

import Card from "../../components/Card";
import MainTemplate from "../../components/MainTemplate";
import Table from "../../components/Table";
import Label from "../../components/Label";
import { BtnAction, Footer, TitleList } from "./styled";
import api from "../../services/api";
import ModalAdicionarIndice from "./ModalAdicionarIndice";
import SelectInput from "../../components/SelectInput";
import ModalExcluirIndice from "./ModalExcluirIndice";
import ModalEditarIndice from "./ModalEditarIndice";
import { snackbarActive } from "../../store/modules/snackbar/action";

export default function Indices() {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [openModalExcluirIndice, setOpenModalExcluirIndice] = useState(false);
  const [openModalEditarIndice, setOpenModalEditarIndice] = useState(false);
  const [page, setPage] = useState(1);
  const [loadingTable, setLoadingTable] = useState(false);
  const [lastPage, setLastPage] = useState(1);

  const [indices, setIndices] = useState([]);
  const [values, setValues] = useState([]);

  const [indiceSelecionado, setIndiceSelecionado] = useState("");
  const [valueIndiceSelected, setValueIndiceSeletected] = useState({});

  const headerTable = [
    "Índice",
    "Acumulado mês",
    "Acumulado ano",
    "Mês de referência",
    "Ações",
  ];

  const apiGetDataIndices = async (activePage) => {
    setLoadingTable(true);

    await api
      .get(`/indexes/values`, {
        params: { page: activePage || page, indice: indiceSelecionado || null },
      })
      .then((response) => {
        setIndices(response.data.indices);
        setLastPage(response.data.valores.last_page);
        setValues(response.data.valores.data);
      })
      .catch((error) => {
        dispatch(
          snackbarActive({
            type: "error",
            message:
              error?.response?.data?.message || "Tente novamente mais tarde!",
          })
        );
      })
      .finally(() => {
        setLoadingTable(false);
      });
  };

  const handlePagination = (page) => {
    setPage(page);
    apiGetDataIndices(page);
  };

  useEffect(() => {
    apiGetDataIndices();
  }, [indiceSelecionado]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MainTemplate title="Índices">
        <Card>
          <Grid container spacing={2}>
            <Grid item md={3} sm={6} xs={12}>
              <Label text="Indice" />

              <SelectInput
                value={indiceSelecionado}
                onChange={(e) => setIndiceSelecionado(e.target.value)}
                id="indice"
                fullWidth
                displayEmpty
              >
                <MenuItem value="">
                  <em>Selecione</em>
                </MenuItem>

                {indices.length > 0 &&
                  indices.map((item) => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.indice}
                    </MenuItem>
                  ))}
              </SelectInput>
            </Grid>
          </Grid>
        </Card>

        <TitleList>
          {
            indices.filter((item) => {
              return indiceSelecionado === item.id;
            }).indice
          }
        </TitleList>

        <Card style={{ padding: 0 }}>
          <Table header={headerTable} loading={loadingTable}>
            {values?.length > 0 &&
              values?.map((item) => (
                <tr key={item.id}>
                  <td>{item.indice}</td>
                  <td>{item.acumulado_mes}</td>
                  <td>{item.acumulado_ano}</td>
                  <td>{item.mes_ano}</td>
                  <td>
                    <Box sx={{ display: "flex", gap: 1 }}>
                      <BtnAction
                        onClick={() => {
                          setOpenModalEditarIndice(true);
                          setValueIndiceSeletected(item);
                        }}
                      >
                        Editar
                      </BtnAction>

                      <BtnAction
                        onClick={() => {
                          setOpenModalExcluirIndice(true);
                          setValueIndiceSeletected(item);
                        }}
                      >
                        Excluir
                      </BtnAction>
                    </Box>
                  </td>
                </tr>
              ))}
          </Table>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 10,
              paddingBottom: 20,
            }}
          >
            <Pagination
              count={lastPage}
              page={page}
              onChange={(event, value) => handlePagination(value)}
            />
          </div>
        </Card>
      </MainTemplate>

      <Footer>
        <Fab
          onClick={() => setOpen(true)}
          disabled={!values.length > 0}
          sx={{
            textTransform: "none",
            backgroundColor: "#DA45F8",
            color: "white",
          }}
          variant="extended"
          color="primary"
          aria-label="add"
        >
          Adicionar índice
          <CheckIcon sx={{ paddingLeft: 1 }} />
        </Fab>
      </Footer>

      <ModalAdicionarIndice
        open={open}
        setOpen={setOpen}
        apiGetDataIndices={apiGetDataIndices}
        indiceSelecionado={indiceSelecionado}
      />

      <ModalEditarIndice
        open={openModalEditarIndice}
        setOpen={setOpenModalEditarIndice}
        apiGetDataIndices={apiGetDataIndices}
        valueIndiceSelected={valueIndiceSelected}
      />

      <ModalExcluirIndice
        open={openModalExcluirIndice}
        setOpen={setOpenModalExcluirIndice}
        apiGetDataIndices={apiGetDataIndices}
        valueIndiceSelected={valueIndiceSelected}
      />
    </>
  );
}
