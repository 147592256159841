import { useState, useEffect, useReducer } from "react";
import { Checkbox, Fab, Grid, InputAdornment, Stack } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDispatch } from "react-redux";
import CheckIcon from "@mui/icons-material/Check";

import Button from "../Button";
import ButtonCancel from "../ButtonCancel";
import { Card, Empty, Calendar, Horarios, CardHorario, Footer } from "./styled";
import Input from "../Input";
import api from "../../services/api";
import { snackbarActive } from "../../store/modules/snackbar/action";

export default function Modulacao({ validityMonth, contratId, setStep }) {
  const dispatch = useDispatch();

  const [daysCalendar, setDaysCalendar] = useState([]);
  const [dayActive, setDayActive] = useState();
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);
  const [daySelect, setDaySelect] = useState(null);
  const [daysSelecteds, setDaysSelecteds] = useState([]);

  const meses = {
    1: "janeiro",
    2: "fevereiro",
    3: "março",
    4: "abril",
    5: "maio",
    6: "junho",
    7: "julho",
    8: "agosto",
    9: "setembro",
    10: "outubro",
    11: "novembro",
    12: "dezembro",
  };

  const getDaysInMonth = (month, year) => {
    let date = new Date(year, month, 1);
    let days = [];
    while (date.getMonth() === month) {
      days.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return days;
  };

  const fillEmpty = (quantity = 0) => {
    const listEmptyComponent = [];

    for (let x = 0; x < quantity; x++) {
      listEmptyComponent.push(<Empty key={x} />);
    }

    return <>{listEmptyComponent}</>;
  };

  const handleInputValue = (hour, value) => {
    let teste = dayActive.activitys;

    let result = teste.find((obj) => {
      return obj.hour === hour;
    });

    result.value = value;

    forceUpdate();
  };

  const verifyIfHaveActivity = (activity) => {
    let cont = 0;

    for (let x = 0; x < activity?.length; x++) {
      activity[x].value.trim() && cont++;
    }

    return cont;
  };

  const selectedDay = (value, event) => {
    if (event.target.checked) {
      setDaysSelecteds([...daysSelecteds, value]);
    } else {
      let list = daysSelecteds;

      let result = list.filter((obj) => {
        return obj.date !== value.date;
      });

      setDaysSelecteds(result);
    }
  };

  const applyDaysActivitys = () => {
    for (let x in daysSelecteds) {
      for (let y in daysSelecteds[x].activitys) {
        daysSelecteds[x].activitys[y].value = daySelect.activitys[y].value;
      }
    }

    forceUpdate();
    setDaySelect(null);
  };

  const apiInsertCalendarInContract = async () => {
    let arrayDaysWithVolume = [];

    for (let i = 0; i < daysCalendar.length; i++) {
      for (let x = 0; x < daysCalendar[i].activitys.length; x++) {
        if (daysCalendar[i].activitys[x].value) {
          arrayDaysWithVolume.push({
            dia: `${daysCalendar[i].date.getFullYear()}-${(
              "0" +
              (daysCalendar[i].date.getMonth() + 1)
            ).slice(-2)}-${("0" + daysCalendar[i].date.getDate()).slice(-2)}`,
            hora: daysCalendar[i].activitys[x].hour.split(":")[0],
            volume: daysCalendar[i].activitys[x].value,
          });
        }
      }
    }

    await api
      .post(`/contracts/${contratId}/calendar`, {
        moments: arrayDaysWithVolume,
      })
      .then((response) => {
        setStep(7);
      })
      .catch((error) => {
        dispatch(
          snackbarActive({
            type: "error",
            message:
              error?.response?.data?.message || "Tente novamente mais tarde!",
          })
        );
      })
      .finally(() => {});
  };

  useEffect(() => {
    let date = new Date(validityMonth.year, validityMonth.month - 1, 1),
      y = date.getFullYear(),
      m = date.getMonth();

    let list = getDaysInMonth(m, y);

    let newList = list.map((value) => ({
      date: value,
      activitys: [
        { hour: "00:00", value: "" },
        { hour: "01:00", value: "" },
        { hour: "02:00", value: "" },
        { hour: "03:00", value: "" },
        { hour: "04:00", value: "" },
        { hour: "05:00", value: "" },
        { hour: "06:00", value: "" },
        { hour: "07:00", value: "" },
        { hour: "08:00", value: "" },
        { hour: "09:00", value: "" },
        { hour: "10:00", value: "" },
        { hour: "11:00", value: "" },
        { hour: "12:00", value: "" },
        { hour: "13:00", value: "" },
        { hour: "14:00", value: "" },
        { hour: "15:00", value: "" },
        { hour: "16:00", value: "" },
        { hour: "17:00", value: "" },
        { hour: "18:00", value: "" },
        { hour: "19:00", value: "" },
        { hour: "20:00", value: "" },
        { hour: "21:00", value: "" },
        { hour: "22:00", value: "" },
        { hour: "23:00", value: "" },
      ],
    }));

    setDaysCalendar(newList);
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={9}>
          <h4>
            Modulação ({meses[Number(validityMonth.month)]}/{validityMonth.year}
            )
          </h4>

          <Stack direction="row" spacing={2}>
            <div style={{ flex: 1, textAlign: "center" }}>
              <p>Domingo</p>
            </div>
            <div style={{ flex: 1, textAlign: "center" }}>
              <p>Segunda-feira</p>
            </div>
            <div style={{ flex: 1, textAlign: "center" }}>
              <p>Terça-feira</p>
            </div>
            <div style={{ flex: 1, textAlign: "center" }}>
              <p>Quarta-feira</p>
            </div>
            <div style={{ flex: 1, textAlign: "center" }}>
              <p>Quinta-feira</p>
            </div>
            <div style={{ flex: 1, textAlign: "center" }}>
              <p>Sexta-feira</p>
            </div>
            <div style={{ flex: 1, textAlign: "center" }}>
              <p>Sábado</p>
            </div>
          </Stack>

          <Calendar>
            {fillEmpty(daysCalendar[0]?.date.getDay())}

            {daysCalendar.map((value) => (
              <>
                <Card
                  activeComplete={verifyIfHaveActivity(value.activitys)}
                  active={dayActive === value}
                  onClick={() => setDayActive(value)}
                  key={value.date.getDate()}
                >
                  {verifyIfHaveActivity(value.activitys) ? (
                    <>
                      {!daySelect && (
                        <div style={{ position: "relative", zIndex: 15 }}>
                          <IconButton
                            style={{ position: "absolute", right: 0 }}
                            aria-label="more"
                            id={`long-button-${value.date.getDate()}`}
                            aria-controls={
                              menuOpen
                                ? `long-menu-${value.date.getDate()}`
                                : undefined
                            }
                            aria-expanded={menuOpen ? `true` : undefined}
                            aria-haspopup="true"
                            onClick={(event) => {
                              setAnchorEl(event.currentTarget);
                            }}
                          >
                            <MoreVertIcon />
                          </IconButton>

                          <Menu
                            disableScrollLock={true}
                            id={`long-button-${value.date.getDate()}`}
                            MenuListProps={{
                              "aria-labelledby": `long-button-${value.date.getDate()}`,
                            }}
                            anchorEl={anchorEl}
                            open={
                              anchorEl?.id ===
                              `long-button-${value.date.getDate()}`
                            }
                            onClose={() => setAnchorEl(null)}
                          >
                            <MenuItem
                              onClick={() => {
                                setAnchorEl(null);
                                setDaySelect(value);
                              }}
                            >
                              Replicar seleção para
                            </MenuItem>
                          </Menu>
                        </div>
                      )}
                    </>
                  ) : (
                    <></>
                  )}

                  {daySelect && (
                    <>
                      {daySelect !== value && (
                        <div style={{ position: "relative", zIndex: 15 }}>
                          <Checkbox
                            onClick={(e) => selectedDay(value, e)}
                            sx={{ position: "absolute", right: 0 }}
                          />
                        </div>
                      )}
                    </>
                  )}

                  <h3>{value.date.getDate()}</h3>
                  {verifyIfHaveActivity(value.activitys) ? (
                    <p>
                      <span>{verifyIfHaveActivity(value.activitys)}</span>{" "}
                      horário(s) preenchidos
                    </p>
                  ) : (
                    <></>
                  )}
                </Card>
              </>
            ))}
          </Calendar>
        </Grid>
        <Grid item md={3}>
          {dayActive && (
            <>
              <h4>Consumo por hora</h4>

              <Horarios>
                {dayActive?.activitys?.map((value, index) => (
                  <CardHorario key={value.hour} active={value.value.trim()}>
                    <h3>{value.hour}</h3>

                    <Input
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">R$</InputAdornment>
                        ),
                      }}
                      value={value.value}
                      placeholder="por hora"
                      onInput={(e) =>
                        handleInputValue(value.hour, e.target.value)
                      }
                    />
                  </CardHorario>
                ))}
              </Horarios>
            </>
          )}
        </Grid>
      </Grid>

      {daySelect && (
        <div style={{ display: "flex", gap: "10px", marginTop: 10 }}>
          <ButtonCancel
            onClick={() => setDaySelect(null)}
            text="Cancelar Seleção"
          />

          <Button onClick={() => applyDaysActivitys()} text="Aplicar Seleção" />
        </div>
      )}

      <Footer>
        <Fab
          onClick={() => apiInsertCalendarInContract()}
          sx={{
            textTransform: "none",
            backgroundColor: "#DA45F8",
            color: "white",
          }}
          variant="extended"
          color="primary"
          aria-label="add"
        >
          Finalizar modulação
          <CheckIcon sx={{ paddingLeft: 1 }} />
        </Fab>
      </Footer>
    </>
  );
}
