import { useRef } from "react";
import { Grid } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import Label from "../../../../components/Label";
import MainModal from "../../../../components/MainModal";
import { MessageError, ModalButtonArea } from "./styled";
import api from "../../../../services/api";
import Input from "../../../../components/Input";
import { loadingFullActive } from "../../../../store/modules/loadingFull/action";
import { snackbarActive } from "../../../../store/modules/snackbar/action";

export default function ModalChangePassword({ openModal, setOpenModal, id }) {
  const {
    handleSubmit,
    reset,
    control,
    watch,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();

  const password = useRef({});
  password.current = watch("newPassword", "");

  const apiChangePassword = async (data) => {
    dispatch(loadingFullActive(true));

    await api
      .put(`/users/${id}/password`, {
        password: data.newPassword,
        password_confirmation: data.password_repeat,
      })
      .then((response) => {
        dispatch(
          snackbarActive({
            type: "success",
            message: response?.data?.message || "Senha alterada com sucesso!",
          })
        );

        reset();
        setOpenModal(false);
      })
      .catch((error) => {
        dispatch(
          snackbarActive({
            type: "error",
            message:
              error?.response?.data?.message || "Tente novamente mais tarde!",
          })
        );
      })
      .finally(() => {
        dispatch(loadingFullActive(false));
      });
  };

  return (
    <>
      <MainModal open={openModal} setOpen={setOpenModal}>
        <h2 style={{ margin: 0 }}>Alterar Senha</h2>

        <Grid mt={2} container spacing={2}>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <Label text="Nova Senha" />

            <Controller
              name="newPassword"
              control={control}
              rules={{
                required: "Campo necessário",
                minLength: {
                  value: 6,
                  message: "Precisa ter mais de 6 caracteres",
                },
              }}
              defaultValue=""
              render={({ field, ref }) => (
                <Input
                  {...field}
                  ref={ref}
                  error={errors.newPassword ? true : false}
                  fullWidth
                  type="password"
                  placeholder="Digite a senha nova"
                  variant="outlined"
                />
              )}
            />

            {errors.newPassword && (
              <MessageError>{errors.newPassword.message}</MessageError>
            )}
          </Grid>

          <Grid item md={6} sm={6} xs={12}>
            <Label text="Confirmar nova senha" />

            <Controller
              name="password_repeat"
              control={control}
              rules={{
                required: "Campo necessário",
                validate: (value) =>
                  value === password.current || "Senha não estão iguais",
              }}
              defaultValue=""
              render={({ field, ref }) => (
                <Input
                  {...field}
                  ref={ref}
                  error={errors.password_repeat ? true : false}
                  fullWidth
                  type="password"
                  placeholder="Digite a senha nova novamente"
                  variant="outlined"
                />
              )}
            />

            {errors.password_repeat && (
              <MessageError>{errors.password_repeat.message}</MessageError>
            )}
          </Grid>
        </Grid>

        <ModalButtonArea>
          <button onClick={handleSubmit(apiChangePassword)}>Alterar</button>
          <button onClick={() => setOpenModal(false)}>Cancelar</button>
        </ModalButtonArea>
      </MainModal>
    </>
  );
}
