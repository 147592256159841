import { Box, Modal } from "@mui/material";

export default function MainModal(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { md: props.width ? props.width : 600, sm: "70%", xs: "95%" },
    bgcolor: "background.paper",
    borderRadius: 2,
    boxShadow: 24,
    p: { md: 4, xs: 3 },
  };

  return (
    <>
      <Modal
        open={props.open}
        onClose={() => props.setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>{props.children}</Box>
      </Modal>
    </>
  );
}
