import { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import CCEE from "./pages/CCEE";
import Companies from "./pages/Companies";
import CreateContract from "./pages/CreateContract";
import Dashboard from "./pages/Dashboard";
import Holidays from "./pages/Holidays";
import Login from "./pages/Login";
import ParamsContract from "./pages/ParamsContract";
import Tranches from "./pages/Tranches";
import { logar } from "./store/modules/users/actions";
import Users from "./pages/Users";
import ProfileAccess from "./pages/ProfileAccess";
import ParamsSystem from "./pages/ParamsSystem";
import ParamsProtheus from "./pages/ParamsProtheus";
import PLD from "./pages/PLD";
import Indices from "./pages/Indices";
import AtualizacaoPeriodo from "./pages/AtualizacaoPeriodo";
import ReajusteContrato from "./pages/ReajusteContrato";
import SazonalidadeModulacao from "./pages/SazonalidadeModulacao";
import LoadingFull from "./components/LoadingFull";
import Snackbar from "./components/Snackbar";

export default function App() {
  const dispatch = useDispatch();

  const snackbarData = useSelector((state) => state.snackbar);

  const loadingFull = useSelector((state) => state.loadingFull);

  const [snackbarState, setSnackbarState] = useState(false);

  if (localStorage?.getItem("user")) {
    const user = JSON.parse(localStorage.getItem("user"));

    if (user) {
      dispatch(logar(user));
    }
  }

  useEffect(() => {
    if (snackbarData?.message) {
      setSnackbarState(true);
    }
  }, [snackbarData]);

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <LoginRedirect>
              <Login />
            </LoginRedirect>
          }
        />

        <Route
          index
          path="*"
          element={
            <RequireAuthAndPermission permission="Dashboard">
              <Dashboard />
            </RequireAuthAndPermission>
          }
        />

        <Route
          path="/parametros-contrato"
          element={
            <RequireAuthAndPermission permission="Parâmetros de contrato">
              <ParamsContract />
            </RequireAuthAndPermission>
          }
        />

        <Route
          path="/adicionar-contrato"
          element={
            <RequireAuthAndPermission permission="Adicionar contrato">
              <CreateContract />
            </RequireAuthAndPermission>
          }
        />

        <Route
          path="/plds"
          element={
            <RequireAuthAndPermission permission="PLD">
              <PLD />
            </RequireAuthAndPermission>
          }
        />

        <Route
          path="/atualizacao-periodo"
          element={
            <RequireAuthAndPermission permission="Atualização por Período">
              <AtualizacaoPeriodo />
            </RequireAuthAndPermission>
          }
        />

        <Route
          path="/indices"
          element={
            <RequireAuthAndPermission permission="Índices">
              <Indices />
            </RequireAuthAndPermission>
          }
        />

        <Route
          path="/reajuste-contrato"
          element={
            <RequireAuthAndPermission permission="Reajuste de Contrato">
              <ReajusteContrato />
            </RequireAuthAndPermission>
          }
        />

        <Route
          path="/sazonalidade-modulacao"
          element={
            <RequireAuthAndPermission permission="Sazonalidade e Modulação">
              <SazonalidadeModulacao />
            </RequireAuthAndPermission>
          }
        />

        <Route
          path="/tranches"
          element={
            <RequireAuthAndPermission permission="Tranches">
              <Tranches />
            </RequireAuthAndPermission>
          }
        />

        <Route
          path="/empresas"
          element={
            <RequireAuthAndPermission permission="Empresas">
              <Companies />
            </RequireAuthAndPermission>
          }
        />

        <Route
          path="/usuarios"
          element={
            <RequireAuthAndPermission permission="Usuários">
              <Users />
            </RequireAuthAndPermission>
          }
        />

        <Route
          path="/perfis-acesso"
          element={
            <RequireAuthAndPermission permission="Perfis de acesso">
              <ProfileAccess />
            </RequireAuthAndPermission>
          }
        />

        <Route
          path="/ccee"
          element={
            <RequireAuthAndPermission permission="Credenciais CCEE">
              <CCEE />
            </RequireAuthAndPermission>
          }
        />

        <Route
          path="/parametros-sistema"
          element={
            <RequireAuthAndPermission permission="Parâmetros do sistemas">
              <ParamsSystem />
            </RequireAuthAndPermission>
          }
        />

        <Route
          path="/gestao-feriados"
          element={
            <RequireAuthAndPermission permission="Gestão de feriados">
              <Holidays />
            </RequireAuthAndPermission>
          }
        />

        {/* <Route
        path="/parametros-protheus"
        element={
          <RequireAuthAndPermission permission="Parâmetros do Protheus">
            <ParamsProtheus />
          </RequireAuthAndPermission>
        }
      /> */}
      </Routes>

      <Snackbar
        state={snackbarState}
        setState={setSnackbarState}
        message={snackbarData?.message}
        type={snackbarData?.type}
      />

      <LoadingFull open={loadingFull} />
    </>
  );
}

function LoginRedirect({ children }) {
  const user = useSelector((state) => state.users);

  if (!user) {
    return children;
  }

  return <Navigate to="/dashboard" replace />;
}

function RequireAuthAndPermission({ children, permission }) {
  const user = useSelector((state) => state.users);

  if (
    user &&
    (user?.permissions?.includes(permission) || permission === "Dashboard")
  ) {
    return children;
  }

  return <Navigate to="/" replace />;
}
