import { useEffect, useRef, useState } from "react";
import { Grid, MenuItem, Pagination } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import Button from "../../components/Button";
import Card from "../../components/Card";
import Input from "../../components/Input";
import Label from "../../components/Label";
import MainModal from "../../components/MainModal";
import MainTemplate from "../../components/MainTemplate";
import SelectInput from "../../components/SelectInput";
import Table from "../../components/Table";
import api from "../../services/api";
import ModalChangePassword from "./components/ModalChangePassword";
import ModalEdit from "./components/ModalEdit";
import { BtnAction, MessageError, ModalButtonArea, TitleList } from "./styled";
import { loadingFullActive } from "../../store/modules/loadingFull/action";
import { snackbarActive } from "../../store/modules/snackbar/action";

export default function Users() {
  const {
    handleSubmit,
    reset,
    control,
    watch,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();

  const [userIdSelected, setUserIdSelected] = useState();
  const [openModalActive, setOpenModalActive] = useState(false);
  const [openModalDisable, setOpenModalDisable] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [openModalChangePassword, setOpenModalChangePassword] = useState(false);

  const [userDataEdit, setUserDataEdit] = useState(false);

  const password = useRef({});
  password.current = watch("password", "");

  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [contentTable, setContentTable] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);

  const [profileSelect, setProfileSelect] = useState([]);

  const headerTable = ["ID", "Nome", "Email", "Perfil", "Status", "Ações"];

  const apiCreateUser = async (data) => {
    dispatch(loadingFullActive(true));

    await api
      .post("/users", {
        name: data.name,
        email: data.email,
        password: data.password,
        profiles_id: data.profile,
      })
      .then((response) => {
        dispatch(
          snackbarActive({
            type: "success",
            message:
              response?.data?.message || "Usuário adicionado com sucesso!",
          })
        );

        getDataApiUsers();
        reset();
      })
      .catch((error) => {
        dispatch(
          snackbarActive({
            type: "error",
            message:
              error?.response?.data?.message || "Tente novamente mais tarde!",
          })
        );
      })
      .finally(() => {
        dispatch(loadingFullActive(false));
      });
  };

  const getDataApiProfiles = async () => {
    dispatch(loadingFullActive(true));

    await api
      .get("/profiles?no-pagination")
      .then((response) => {
        setProfileSelect(response.data.data);
      })
      .catch((error) => {
        dispatch(
          snackbarActive({
            type: "error",
            message:
              error?.response?.data?.message || "Tente novamente mais tarde!",
          })
        );
      })
      .finally(() => {
        dispatch(loadingFullActive(false));
      });
  };

  const openModalActivate = (id) => {
    setUserIdSelected(id);
    setOpenModalActive(true);
  };

  const openModalDesative = (id) => {
    setUserIdSelected(id);
    setOpenModalDisable(true);
  };

  const openModalEditable = (id) => {
    getDataApiUser(id);
    setUserIdSelected(id);
  };

  const openModalChangePass = (id) => {
    setUserIdSelected(id);
    setOpenModalChangePassword(true);
  };

  const handlePagination = (page) => {
    setPage(page);
    getDataApiUsers(page);
  };

  const reloadListUsers = () => {
    getDataApiUsers(page);
  };

  const apiActiveUser = async () => {
    dispatch(loadingFullActive(true));
    setOpenModalActive(false);

    await api
      .post(`/users/${userIdSelected}/activate`, null)
      .then((response) => {
        dispatch(
          snackbarActive({
            type: "success",
            message: response?.data?.message || "Usuário ativado com sucesso!",
          })
        );

        getDataApiUsers(page);
      })
      .catch((error) => {
        dispatch(
          snackbarActive({
            type: "error",
            message:
              error?.response?.data?.message || "Tente novamente mais tarde!",
          })
        );
      })
      .finally(() => {
        dispatch(loadingFullActive(false));
      });
  };

  const apiDisableUser = async () => {
    dispatch(loadingFullActive(true));
    setOpenModalDisable(false);

    await api
      .delete(`/users/${userIdSelected}/deactivate`)
      .then((response) => {
        dispatch(
          snackbarActive({
            type: "success",
            message:
              response?.data?.message || "Usuário desativado com sucesso!",
          })
        );

        getDataApiUsers(page);
      })
      .catch((error) => {
        dispatch(
          snackbarActive({
            type: "error",
            message:
              error?.response?.data?.message || "Tente novamente mais tarde!",
          })
        );
      })
      .finally(() => {
        dispatch(loadingFullActive(false));
      });
  };

  const getDataApiUser = async (id) => {
    dispatch(loadingFullActive(true));

    await api
      .get(`/users/${id}`)
      .then((response) => {
        setOpenModalEdit(true);
        setUserDataEdit(response.data);
      })
      .catch((error) => {
        dispatch(
          snackbarActive({
            type: "error",
            message:
              error?.response?.data?.message || "Tente novamente mais tarde!",
          })
        );
      })
      .finally(() => {
        dispatch(loadingFullActive(false));
      });
  };

  async function getDataApiUsers(activePage) {
    setLoadingTable(true);

    await api
      .get(`/users`, { params: { page: activePage || page } })
      .then((response) => {
        let list = response.data.data;

        for (let i = 0; i < list.length; i++) {
          list[i].acoes = (
            <div style={{ display: "flex" }}>
              <BtnAction
                style={{ marginRight: 10 }}
                onClick={() => openModalEditable(list[i].id)}
              >
                Editar
              </BtnAction>
              <BtnAction
                style={{ marginRight: 10 }}
                onClick={() => openModalChangePass(list[i].id)}
              >
                Alterar Senha
              </BtnAction>
              {list[i].active === "Ativo" ? (
                <BtnAction onClick={() => openModalDesative(list[i].id)}>
                  Desativar
                </BtnAction>
              ) : (
                <BtnAction onClick={() => openModalActivate(list[i].id)}>
                  Ativar
                </BtnAction>
              )}
            </div>
          );
        }

        setContentTable(list);
        setLastPage(response.data.last_page);
      })
      .catch((error) => {
        dispatch(
          snackbarActive({
            type: "error",
            message:
              error?.response?.data?.message || "Tente novamente mais tarde!",
          })
        );
      })
      .finally(() => {
        setLoadingTable(false);
      });
  }

  useEffect(() => {
    getDataApiUsers();
    getDataApiProfiles();
  }, []);

  return (
    <>
      <MainTemplate title="Adicionar Usuário">
        <Card>
          <Grid container spacing={2}>
            <Grid item md={3} xs={6}>
              <Label text="Nome Completo" />

              <Controller
                name="name"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field, ref }) => (
                  <Input
                    {...field}
                    ref={ref}
                    error={errors.name ? true : false}
                    fullWidth
                    type="text"
                    placeholder="Digite o nome"
                    variant="outlined"
                  />
                )}
              />

              {errors.name && <MessageError>Campo necessário</MessageError>}
            </Grid>
            <Grid item md={3} xs={6}>
              <Label text="Email" />

              <Controller
                name="email"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field, ref }) => (
                  <Input
                    {...field}
                    ref={ref}
                    error={errors.email ? true : false}
                    fullWidth
                    type="email"
                    placeholder="Digite o email"
                    variant="outlined"
                  />
                )}
              />

              {errors.email && <MessageError>Campo necessário</MessageError>}
            </Grid>
          </Grid>

          <Grid mt={0} container spacing={2}>
            <Grid item md={3} xs={6}>
              <Label text="Senha" />

              <Controller
                name="password"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field, ref }) => (
                  <Input
                    {...field}
                    ref={ref}
                    error={errors.password ? true : false}
                    fullWidth
                    type="password"
                    placeholder="Digite a senha"
                    variant="outlined"
                  />
                )}
              />

              {errors.password && <MessageError>Campo necessário</MessageError>}
            </Grid>
            <Grid item md={3} xs={6}>
              <Label text="Confirmar senha" />

              <Controller
                name="password_repeat"
                control={control}
                rules={{
                  required: "Campo necessário",
                  validate: (value) =>
                    value === password.current || "Senha não estão iguais",
                }}
                defaultValue=""
                render={({ field, ref }) => (
                  <Input
                    {...field}
                    ref={ref}
                    error={errors.password_repeat ? true : false}
                    fullWidth
                    type="password"
                    placeholder="Digite a senha novamente"
                    variant="outlined"
                  />
                )}
              />

              {errors.password_repeat && (
                <MessageError>{errors.password_repeat.message}</MessageError>
              )}
            </Grid>
          </Grid>

          <Grid mt={0} container spacing={2}>
            <Grid item md={3} xs={6}>
              <Label text="Perfil de acesso" />

              <Controller
                name="profile"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field, ref }) => (
                  <SelectInput
                    {...field}
                    ref={ref}
                    error={errors.profile ? true : false}
                    fullWidth
                    displayEmpty
                  >
                    <MenuItem value="">
                      <em>Selecione</em>
                    </MenuItem>
                    {profileSelect.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </SelectInput>
                )}
              />

              {errors.profile && <MessageError>Campo necessário</MessageError>}
            </Grid>
          </Grid>

          <Button
            text="Adicionar"
            onClick={handleSubmit(apiCreateUser)}
            sx={{ mt: 4, mb: 1 }}
          />
        </Card>

        <TitleList>Usuários</TitleList>

        <Card style={{ padding: 0 }}>
          <Table header={headerTable} loading={loadingTable}>
            {contentTable?.length > 0 &&
              contentTable.map((item) => (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.name}</td>
                  <td>{item["usuário"]}</td>
                  <td>{item.profile}</td>
                  <td>{item.active}</td>
                  <td>{item.acoes}</td>
                </tr>
              ))}
          </Table>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 15,
              paddingBottom: 10,
            }}
          >
            <Pagination
              count={lastPage}
              page={page}
              onChange={(event, value) => handlePagination(value)}
            />
          </div>
        </Card>
      </MainTemplate>

      <MainModal
        width={450}
        open={openModalActive}
        setOpen={setOpenModalActive}
      >
        <h2 style={{ margin: 0 }}>Confirmação</h2>

        <p>Deseja realmente ativar esse usuário?</p>

        <ModalButtonArea>
          <button onClick={() => apiActiveUser()}>Sim</button>
          <button onClick={() => setOpenModalActive(false)}>Cancelar</button>
        </ModalButtonArea>
      </MainModal>

      <MainModal
        width={450}
        open={openModalDisable}
        setOpen={setOpenModalDisable}
      >
        <h2 style={{ margin: 0 }}>Confirmação</h2>

        <p>Deseja realmente desativar esse usuário?</p>

        <ModalButtonArea>
          <button onClick={() => apiDisableUser()}>Sim</button>
          <button onClick={() => setOpenModalDisable(false)}>Cancelar</button>
        </ModalButtonArea>
      </MainModal>

      <ModalChangePassword
        openModal={openModalChangePassword}
        setOpenModal={setOpenModalChangePassword}
        id={userIdSelected}
      />

      <ModalEdit
        openModal={openModalEdit}
        setOpenModal={setOpenModalEdit}
        selectProfile={profileSelect}
        user={userDataEdit}
        reloadList={reloadListUsers}
      />
    </>
  );
}
