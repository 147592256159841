import styled from "styled-components";

export const Step = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #eaeaea;
  border-top: 1px solid #eaeaea;
  justify-content: space-between;
  padding: 1em 0;

  :first-child {
    border-top: 0 !important;
  }

  :last-child {
    border-bottom: 0 !important;
  }

  h4 {
    align-self: center;
    font-weight: 700;
    font-size: 14px;
    color: #ababb6;
  }

  h3 {
    align-self: center;
    font-weight: 700;
    font-size: 14px;
    color: black;
  }

  span {
    display: block;
    background: ${(props) => (props.ativo ? "#3FC2DA" : "#ABABB6")};
    border-radius: 34px;
    padding: 0.5em 1em;
    border: 0;
    align-self: center;
    color: white;
  }

  button {
    display: flex;
    align-self: center;
    background: #da45f8;
    border-radius: 34px;
    padding: 0.5em 1em;
    border: 0;
    align-self: center;
    color: white;

    span {
      padding: 0;
      font-size: 14px;
      background: transparent;
      padding-right: 1em;
      font-weight: 700;
    }

    svg {
      font-size: 20px;
    }
  }

  button:hover {
    opacity: 0.6;
  }
`;
