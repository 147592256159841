import { useEffect, useState } from "react";
import { Fab } from "@mui/material";
import { useForm } from "react-hook-form";
import CheckIcon from "@mui/icons-material/Check";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import BallotIcon from "@mui/icons-material/Ballot";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import Card from "../../components/Card";
import MainTemplate from "../../components/MainTemplate";
import {
  SuccessArea,
  ModulacaoUploadArea,
  BackgroundIcon,
  Footer,
} from "./styled";
import Modulacao from "../../components/Modulacao";
import api from "../../services/api";
import StepDadosGerais from "./components/StepDadosGerais";
import StepValoresVigencia from "./components/StepValoresVigencia";
import StepDadosComplementares from "./components/StepDadosComplementares";
import StepDadosProtheus from "./components/StepDadosProtheus";
import ExemploModulo from "../../assets/xlsx/exemploModulacaoCadastro.xlsx";
import { loadingFullActive } from "../../store/modules/loadingFull/action";
import { snackbarActive } from "../../store/modules/snackbar/action";

export default function CreateContract() {
  const {
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [step, setStep] = useState(1);

  const [enableEdit, setEnableEdit] = useState(null);
  const [modulation, setModulation] = useState(false);
  const [contractBBCE, setContractBBCE] = useState(false);
  const [validityMonthsQuantity, setValidityMonthsQuantity] = useState(null);
  const [validityMonth, setValidityMonth] = useState({
    month: null,
    year: null,
  });

  const [typeProduct, setTypeProduct] = useState("");

  const [contratId, setContractId] = useState("");

  const [codInterno, setCodInterno] = useState("");

  const onSubmit = (data) => setEnableEdit(null);

  const apiCreateContract = async (data) => {
    dispatch(loadingFullActive(true));

    const sendingData = {
      operacoes_id: data.fatoContrato,
      ponta_a: data.pontaA?.value,
      ponta_b: data.pontaB?.value,
      flags_id: data.flag,
      submercados_entrega_id: data.submercado,
      tipos_operacao_id: data.tipoOperacao,
      fontes_id: data.tipoFonte,
      ressarcimento:
        data.ressarcimentoTUSD &&
        String(data.ressarcimentoTUSD).replaceAll(".", "").replace(",", "."),
      montante:
        data.montante &&
        String(data.montante).replaceAll(".", "").replace(",", "."),
      unidades_id: data.unidade,
      data_vigencia_inicial: data.vigenciaInicio,
      data_vigencia_final: data.vigenciaFim,
      spread:
        data.spread &&
        String(data.spread).replaceAll(".", "").replace(",", "."),
      preco_energia:
        data.precoEnergia &&
        String(data.precoEnergia).replaceAll(".", "").replace(",", "."),
      tipos_preco_id: data.tipoPreco,
      razoes_negocio_id: data.razaoNegocio,
      vencimento_dias_id: data.vencimentoDia,
      vencimento_tipos_id: data.vencimentoTipo,
      indice_reajuste_id: data.indiceReajuste,
      data_reajuste: data.primeiraDataReajuste,
      tipos_calculo_id: data.tipoCalculo,
      garantias_contratuais_id: data.garantiaContratual,
      data_base: data.dataBaseContrato,
      cod_contrato_fisico: "",
      codigo_bbce: data.codigoBBCE,
      classes_valor_id: data.classeValor,
      centros_custos_id: data.centroCusto,
      produtos_compra_id: data.produtoCompra,
      titulo: data.titulo,
      produtos_venda_id: data.produtoVenda,
      tes_id: data.tes,
      mensagem_nota_id: data.mensagemNota,
    };

    await api
      .post("/contracts/finish", sendingData)
      .then((response) => {
        setContractId(response.data.contract_ids[0]);
        setCodInterno(response.data.cod_interno);

        if (step === 5) {
          modulation ? setStep(6) : setStep(7);
        } else {
          if (step === 6) {
            setStep(7);
          }
        }
      })
      .catch((error) => {
        dispatch(
          snackbarActive({
            type: "error",
            message:
              error?.response?.data?.message || "Tente novamente mais tarde!",
          })
        );
      })
      .finally(() => {
        dispatch(loadingFullActive(false));
      });
  };

  const apiEnvioPlanilhaModulacao = async () => {
    dispatch(loadingFullActive(true));

    const file = document.getElementById("file_modulacao");

    const newData = new FormData();
    newData.append("file", file.files[0]);

    await api
      .post(`/contracts/${contratId}/calendar/excel`, newData)
      .then((response) => {
        dispatch(
          snackbarActive({
            type: "success",
            message: response?.data?.message || "Upload realizado com sucesso!",
          })
        );
      })
      .catch((error) => {
        dispatch(
          snackbarActive({
            type: "error",
            message:
              error?.response?.data?.message ||
              "Arquivo não está igual ao modelo!",
          })
        );
      })
      .finally(() => {
        dispatch(loadingFullActive(false));
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <MainTemplate title="Adicionar contrato">
      <Card style={{ marginBottom: 50 }}>
        {step <= 5 && (
          <>
            <StepDadosGerais
              step={step}
              setStep={setStep}
              control={control}
              errors={errors}
              watch={watch}
              setTypeProduct={setTypeProduct}
              handleSubmit={handleSubmit}
              enableEdit={enableEdit}
              setEnableEdit={setEnableEdit}
              onSubmit={onSubmit}
            />

            <StepValoresVigencia
              step={step}
              control={control}
              errors={errors}
              setValue={setValue}
              handleSubmit={handleSubmit}
              enableEdit={enableEdit}
              setEnableEdit={setEnableEdit}
              watch={watch}
              onSubmit={onSubmit}
              setStep={setStep}
              setValidityMonthsQuantity={setValidityMonthsQuantity}
              setValidityMonth={setValidityMonth}
            />

            <StepDadosComplementares
              step={step}
              control={control}
              errors={errors}
              handleSubmit={handleSubmit}
              enableEdit={enableEdit}
              setEnableEdit={setEnableEdit}
              onSubmit={onSubmit}
              setStep={setStep}
              modulation={modulation}
              setModulation={setModulation}
              contractBBCE={contractBBCE}
              setContractBBCE={setContractBBCE}
              watch={watch}
            />

            <StepDadosProtheus
              step={step}
              control={control}
              errors={errors}
              handleSubmit={handleSubmit}
              enableEdit={enableEdit}
              setEnableEdit={setEnableEdit}
              onSubmit={onSubmit}
              setStep={setStep}
              typeProduct={typeProduct}
            />
          </>
        )}

        {step === 6 && (
          <>
            {validityMonthsQuantity <= 1 && (
              <Modulacao
                validityMonth={validityMonth}
                contratId={contratId}
                setStep={setStep}
              />
            )}

            {validityMonthsQuantity > 1 && (
              <div style={{ marginTop: 10, marginBottom: 20 }}>
                <h3>Modulação</h3>

                <ModulacaoUploadArea>
                  <div>
                    <BackgroundIcon>
                      <BallotIcon />
                    </BackgroundIcon>
                    <h3>Você precisa importar uma planilha com a modulação</h3>
                    <p>
                      Para contratos modulares com mais de um mês de vigência,
                      uma planilha com
                      <br />a modulação deve ser importada de acordo com
                      <a href={ExemploModulo} download>
                        {" "}
                        o modelo
                      </a>
                      .
                    </p>

                    <input
                      type="file"
                      style={{ display: "none" }}
                      id="file_modulacao"
                      onChange={apiEnvioPlanilhaModulacao}
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    />

                    <div
                      style={{
                        alignSelf: "center",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <button
                        onClick={() =>
                          document.getElementById("file_modulacao").click()
                        }
                        style={{ marginRight: 10 }}
                      >
                        Importar XLSX
                      </button>

                      <Link to="/dashboard" style={{ marginRight: 10 }}>
                        <button>Seguir para contratos</button>
                      </Link>

                      <button onClick={() => navigate(0)}>
                        Adicionar novo contrato
                      </button>
                    </div>
                  </div>
                </ModulacaoUploadArea>
              </div>
            )}
          </>
        )}

        {step === 7 && (
          <SuccessArea>
            <div>
              <CheckCircleIcon />
              <h3>Contrato adicionado com sucesso!</h3>
              {/* Erik */}
              <p>
                O código do contrato adicionado é <strong>{codInterno}</strong>
              </p>
              <div
                style={{
                  marginTop: "1em",
                  flexDirection: "row",
                  gap: "10px",
                }}
              >
                <button onClick={() => navigate(0)}>
                  Adicionar novo contrato
                </button>
                <Link to="/dashboard">
                  <button>Ir para Listagem</button>
                </Link>
              </div>
            </div>
          </SuccessArea>
        )}
      </Card>

      {step < 6 && (
        <Footer>
          <Fab
            disabled={(step === 5 || step === 6) && !enableEdit ? false : true}
            onClick={handleSubmit(apiCreateContract)}
            sx={{
              textTransform: "none",
              backgroundColor: "#DA45F8",
              color: "white",
            }}
            variant="extended"
            color="primary"
            aria-label="add"
          >
            Finalizar lote
            <CheckIcon sx={{ paddingLeft: 1 }} />
          </Fab>
        </Footer>
      )}
    </MainTemplate>
  );
}
