import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Grid, MenuItem, Pagination } from "@mui/material";

import Card from "../../components/Card";
import MainTemplate from "../../components/MainTemplate";
import Table from "../../components/Table";
import Label from "../../components/Label";
import { BtnAction, Description } from "./styled";
import api from "../../services/api";
import SelectInput from "../../components/SelectInput";
import ModalReajusteValor from "./ModalReajusteValor";
import { snackbarActive } from "../../store/modules/snackbar/action";

export default function ReajusteContrato() {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [loadingTable, setLoadingTable] = useState(false);
  const [lastPage, setLastPage] = useState(1);

  const [options, setOptions] = useState([]);
  const [contratos, setContratos] = useState([]);

  const [indiceSelecionado, setIndiceSelecionado] = useState("");
  const [contratoSelecionado, setContratoSelecionado] = useState({});

  const headerTable = [
    "Código do contrato",
    "Preço energia inicial",
    "Preço energia atual",
    "Data de reajuste",
    "Índice de reajuste",
    "Vigência de contrato",
    "Ações",
  ];

  const apiGetDataIndices = async (activePage) => {
    setLoadingTable(true);

    await api
      .get(`/reajustes`, {
        params: { page: activePage || page, tempo: indiceSelecionado || null },
      })
      .then((response) => {
        setContratos(response.data.contracts?.data);
        setLastPage(response.data.contracts?.last_page);
      })
      .catch((error) => {
        dispatch(
          snackbarActive({
            type: "error",
            message:
              error?.response?.data?.message || "Tente novamente mais tarde!",
          })
        );
      })
      .finally(() => {
        setLoadingTable(false);
      });
  };

  const apiGetTempos = async () => {
    await api
      .get("/reajustes/tempos")
      .then((response) => {
        setOptions(response.data);
      })
      .catch((error) => {
        dispatch(
          snackbarActive({
            type: "error",
            message:
              error?.response?.data?.message || "Tente novamente mais tarde!",
          })
        );
      });
  };

  const handlePagination = (page) => {
    setPage(page);
    apiGetDataIndices(page);
  };

  useEffect(() => {
    setPage(1);
    apiGetDataIndices(1);
  }, [indiceSelecionado]);

  useEffect(() => {
    apiGetTempos();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MainTemplate title="Reajuste de contrato">
        <Card>
          <Description>Filtros</Description>

          <Grid container spacing={2}>
            <Grid item md={3} sm={6} xs={12}>
              <Label text="Período" />

              <SelectInput
                value={indiceSelecionado}
                onChange={(e) => setIndiceSelecionado(e.target.value)}
                id="indice"
                fullWidth
                displayEmpty
              >
                <MenuItem value="">
                  <em>Selecione</em>
                </MenuItem>

                {options.length > 0 &&
                  options.map((item) => (
                    <MenuItem value={item.slug} key={item.slug}>
                      {item.label}
                    </MenuItem>
                  ))}
              </SelectInput>
            </Grid>
          </Grid>
        </Card>

        <Card style={{ padding: 0 }}>
          <Table header={headerTable} loading={loadingTable}>
            {contratos?.length > 0 &&
              contratos?.map((item) => (
                <tr key={item.id}>
                  <td>{item["Código do contrato"]}</td>
                  <td>
                    {Number(item["Preço energia inicial"]).toLocaleString(
                      "pt-br",
                      {
                        style: "currency",
                        currency: "BRL",
                      }
                    )}
                  </td>
                  <td>
                    {Number(item["Preço energia atual"]).toLocaleString(
                      "pt-br",
                      {
                        style: "currency",
                        currency: "BRL",
                      }
                    )}
                  </td>
                  <td>{item["Data de reajuste"]}</td>
                  <td>{item["Índice de reajuste"]}</td>
                  <td>
                    {item["Inicio suprimento"]} - {item["Fim suprimento"]}
                  </td>
                  <td>
                    <BtnAction
                      style={{ marginLeft: 10, background: "#B0A5F3" }}
                      onClick={() => {
                        setContratoSelecionado(item);
                        setOpen(true);
                      }}
                    >
                      Reajuste valor
                    </BtnAction>
                  </td>
                </tr>
              ))}
          </Table>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 10,
              paddingBottom: 20,
            }}
          >
            <Pagination
              count={lastPage}
              page={page}
              onChange={(event, value) => handlePagination(value)}
            />
          </div>
        </Card>
      </MainTemplate>

      <ModalReajusteValor
        open={open}
        setOpen={setOpen}
        apiGetDataIndices={apiGetDataIndices}
        contrato={contratoSelecionado}
      />
    </>
  );
}
