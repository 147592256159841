import { Grid } from "@mui/material";
import { useSelector } from "react-redux";

import Container from "../Container";
import Header from "../Header";
import SideMenu from "../SideMenu";

export default function MainTemplate(props) {
  const isMenuMinimized = useSelector((state) => state.configs);

  return (
    <>
      <Header />

      <Grid container>
        <Grid item lg={isMenuMinimized ? 0.5 : 2} md={0} xs={0}>
          <SideMenu isMenuMinimized={isMenuMinimized} />
        </Grid>
        <Grid
          item
          lg={isMenuMinimized ? 11.5 : 10}
          md={12}
          xs={12}
          sx={{ position: { md: "relative", xs: "unset" } }}
        >
          <Container title={props.title}>{props.children}</Container>
        </Grid>
      </Grid>
    </>
  );
}
