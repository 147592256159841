import { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { Controller } from "react-hook-form";
import { Checkbox, FormControlLabel, Grid, MenuItem } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { ButtonArea, Step } from "./styled";
import api from "../../../../services/api";
import MessageError from "../../../../components/MessageError";
import Label from "../../../../components/Label";
import SelectInput from "../../../../components/SelectInput";
import Input from "../../../../components/Input";
import Button from "../../../../components/Button";

export default function StepDadosComplementares({
  step,
  control,
  errors,
  handleSubmit,
  watch,
  enableEdit,
  setEnableEdit,
  onSubmit,
  setStep,
  modulation,
  setModulation,
  contractBBCE,
  setContractBBCE,
}) {
  const [step3Selects, setStep3Selects] = useState({
    garantiaContratual: [],
    vencimentoDias: [],
    vencimentoTipos: [],
    tiposCalculo: [],
    indiceReajuste: [],
  });

  const loadSelectsStep3 = async () => {
    const { data } = await api.get("/contracts/complementary");

    setStep3Selects({
      garantiaContratual: data["garantia-contratual"],
      vencimentoDias: data["vencimento-dias"],
      tiposCalculo: data["calculo-tipos"],
      vencimentoTipos: data["vencimento-tipos"],
      indiceReajuste: data["indice-reajuste"],
    });
  };

  const onSubmitForm3 = (data) => setStep(4);

  useEffect(() => {
    loadSelectsStep3();
  }, []);

  return (
    <>
      <Step ativo={step > 3 ? true : false}>
        {step > 3 ? (
          <h3>DADOS COMPLEMENTARES</h3>
        ) : (
          <h4>DADOS COMPLEMENTARES</h4>
        )}

        {step !== 3 && step !== 5 && (
          <span>
            {step < 3 && "Pendente"}
            {step > 3 && "Completo"}
          </span>
        )}

        {step === 5 && !enableEdit && (
          <button onClick={() => setEnableEdit(3)}>
            <span>Editar seção</span>
            <EditIcon />
          </button>
        )}

        {enableEdit === 3 && (
          <button onClick={handleSubmit(onSubmit)}>
            <span>Salvar seção</span>
            <EditIcon />
          </button>
        )}
      </Step>

      {(step === 3 || step === 5) && (
        <>
          <Grid mt={2} container spacing={2}>
            <Grid item md={2} xs={6}>
              <Label text="Vencimento dia" />

              <Controller
                name="vencimentoDia"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field, ref }) => (
                  <SelectInput
                    {...field}
                    ref={ref}
                    id="vencimentoDia"
                    error={errors.vencimentoDia ? true : false}
                    disabled={step === 3 || enableEdit === 3 ? false : true}
                    fullWidth
                    displayEmpty
                  >
                    <MenuItem value="">
                      <em>Selecione</em>
                    </MenuItem>

                    {step3Selects?.vencimentoDias?.length > 0 &&
                      step3Selects?.vencimentoDias?.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                  </SelectInput>
                )}
              />

              {errors.vencimentoDia && (
                <MessageError>Campo necessário</MessageError>
              )}
            </Grid>
            <Grid item md={2} xs={6}>
              <Label text="Vencimento tipo" />

              <Controller
                name="vencimentoTipo"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field, ref }) => (
                  <SelectInput
                    {...field}
                    ref={ref}
                    id="vencimentoTipo"
                    error={errors.vencimentoTipo ? true : false}
                    disabled={step === 3 || enableEdit === 3 ? false : true}
                    fullWidth
                    displayEmpty
                  >
                    <MenuItem value="">
                      <em>Selecione</em>
                    </MenuItem>

                    {step3Selects?.vencimentoTipos?.length > 0 &&
                      step3Selects?.vencimentoTipos?.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                  </SelectInput>
                )}
              />

              {errors.vencimentoDia && (
                <MessageError>Campo necessário</MessageError>
              )}
            </Grid>
          </Grid>

          <Grid mt={0} container spacing={2}>
            <Grid item md={2} xs={6}>
              <Label text="Indice de reajuste" />

              <Controller
                name="indiceReajuste"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field, ref }) => (
                  <SelectInput
                    {...field}
                    ref={ref}
                    id="indiceReajuste"
                    error={errors.indiceReajuste ? true : false}
                    disabled={step === 3 || enableEdit === 3 ? false : true}
                    fullWidth
                    displayEmpty
                  >
                    <MenuItem value="">
                      <em>Selecione</em>
                    </MenuItem>
                    {step3Selects?.indiceReajuste?.length > 0 &&
                      step3Selects?.indiceReajuste?.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                  </SelectInput>
                )}
              />

              {errors.indiceReajuste && (
                <MessageError>Campo necessário</MessageError>
              )}
            </Grid>
            <Grid item md={2} xs={6}>
              <Label text="Garantia contratual" />

              <Controller
                name="garantiaContratual"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field, ref }) => (
                  <SelectInput
                    {...field}
                    ref={ref}
                    id="garantiaContratual"
                    error={errors.garantiaContratual ? true : false}
                    disabled={step === 3 || enableEdit === 3 ? false : true}
                    fullWidth
                    displayEmpty
                  >
                    <MenuItem value="">
                      <em>Selecione</em>
                    </MenuItem>
                    {step3Selects?.garantiaContratual?.length > 0 &&
                      step3Selects?.garantiaContratual?.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                  </SelectInput>
                )}
              />

              {errors.garantiaContratual && (
                <MessageError>Campo necessário</MessageError>
              )}
            </Grid>
            <Grid item md={2} xs={6}>
              <Label text="Data base do contrato" />

              <Controller
                name="dataBaseContrato"
                control={control}
                defaultValue=""
                rules={{
                  required: watch("indiceReajuste") === 1 ? true : false,
                }}
                render={({ field, ref }) => (
                  <Input
                    {...field}
                    ref={ref}
                    id="dataBaseContrato"
                    error={errors.dataBaseContrato ? true : false}
                    disabled={step === 3 || enableEdit === 3 ? false : true}
                    fullWidth
                    type="date"
                  />
                )}
              />

              {errors.dataBaseContrato && (
                <MessageError>Campo necessário</MessageError>
              )}
            </Grid>
          </Grid>

          {watch("indiceReajuste") === 1 && (
            <Grid mt={0} container spacing={2}>
              <Grid item md={2} xs={6}>
                <Label text="Primeira data de reajuste" />

                <Controller
                  name="primeiraDataReajuste"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field, ref }) => (
                    <Input
                      {...field}
                      ref={ref}
                      id="primeiraDataReajuste"
                      name="primeiraDataReajuste"
                      error={errors.primeiraDataReajuste ? true : false}
                      disabled={step === 3 || enableEdit === 3 ? false : true}
                      fullWidth
                      type="date"
                    />
                  )}
                />

                {errors.primeiraDataReajuste && (
                  <MessageError>Campo necessário</MessageError>
                )}
              </Grid>

              <Grid item md={2} xs={6}>
                <Label text="Tipo de cálculo" />

                <Controller
                  name="tipoCalculo"
                  control={control}
                  rules={{ required: true }}
                  defaultValue=""
                  render={({ field, ref }) => (
                    <SelectInput
                      {...field}
                      ref={ref}
                      id="tipoCalculo"
                      name="tipoCalculo"
                      error={errors.tipoCalculo ? true : false}
                      disabled={step === 3 || enableEdit === 3 ? false : true}
                      fullWidth
                      displayEmpty
                    >
                      <MenuItem value="">
                        <em>Selecione</em>
                      </MenuItem>

                      {step3Selects?.tiposCalculo?.length > 0 &&
                        step3Selects.tiposCalculo?.map((item) => (
                          <MenuItem value={item.value} key={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                    </SelectInput>
                  )}
                />

                {errors.tipoCalculo && (
                  <MessageError>Campo necessário</MessageError>
                )}
              </Grid>
            </Grid>
          )}

          <Grid mt={1} mb={step === 5 ? 4 : 2} container spacing={2}>
            <Grid item md={2} xs={6} alignSelf="center">
              <FormControlLabel
                disabled={step === 3 || enableEdit === 3 ? false : true}
                control={
                  <Checkbox
                    checked={modulation}
                    onChange={(e) => setModulation(e.target.checked)}
                  />
                }
                label="Modulação"
              />
            </Grid>
            <Grid item md={2} xs={6} alignSelf="center">
              <FormControlLabel
                disabled={step === 3 || enableEdit === 3 ? false : true}
                control={
                  <Checkbox
                    checked={contractBBCE}
                    onChange={(e) => setContractBBCE(e.target.checked)}
                  />
                }
                label="Contrato BBCE"
              />
            </Grid>

            {contractBBCE && (
              <Grid
                item
                md={2}
                xs={6}
                alignSelf="center"
                sx={{ position: "relative", mt: { md: 0, xs: 3 } }}
              >
                <div style={{ position: "absolute", top: "-15px" }}>
                  <Label text="Código BBCE" />

                  <Controller
                    name="codigoBBCE"
                    control={control}
                    rules={{ required: true }}
                    defaultValue=""
                    render={({ field, ref }) => (
                      <Input
                        {...field}
                        ref={ref}
                        id="codigoBBCE"
                        error={errors.codigoBBCE ? true : false}
                        disabled={step === 3 || enableEdit === 3 ? false : true}
                        fullWidth
                        placeholder="Código BBCE"
                      />
                    )}
                  />

                  {errors.codigoBBCE && (
                    <MessageError>Campo necessário</MessageError>
                  )}
                </div>
              </Grid>
            )}
          </Grid>

          {step !== 5 && (
            <ButtonArea>
              <button onClick={() => setStep(2)}>
                <ArrowBackIcon />
                <span>Voltar</span>
              </button>

              <Button
                onClick={handleSubmit(onSubmitForm3)}
                sx={{ my: 4, paddingVertical: "1em" }}
                text="Prosseguir"
              />
            </ButtonArea>
          )}
        </>
      )}
    </>
  );
}
