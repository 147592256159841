import { useEffect, useState } from "react";
import {
  FormControlLabel,
  FormGroup,
  Grid,
  Pagination,
  Switch,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import Button from "../../components/Button";
import Card from "../../components/Card";
import Input from "../../components/Input";
import Label from "../../components/Label";
import MainModal from "../../components/MainModal";
import MainTemplate from "../../components/MainTemplate";
import Table from "../../components/Table";
import api from "../../services/api";
import { BtnAction, MessageError, ModalButtonArea, TitleList } from "./styled";
import { loadingFullActive } from "../../store/modules/loadingFull/action";
import { snackbarActive } from "../../store/modules/snackbar/action";

export default function ProfileAccess() {
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [contentTable, setContentTable] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const headerTable = ["ID", "Nome", "Ações"];

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const [profileSelected, setProfileSelected] = useState({});
  const [openModalView, setOpenModalView] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [dataCreatePermissions, setDataCreatePermissions] = useState([]);

  const [switchEdit, setSwitchEdit] = useState([]);

  const handlePagination = (page) => {
    setPage(page);
    getDataApiProfiles(page);
  };

  const handleModalView = (profile) => {
    setProfileSelected(profile);
    setOpenModalView(true);
  };

  const handleModalEdit = (profile) => {
    setProfileSelected(profile);
    checkPermissions(profile);
    setOpenModalEdit(true);
  };

  const checkPermissions = (profile) => {
    let { permissions } = profile;

    setSwitchEdit([]);

    let arrayPermissions = [];

    for (let i = 0; i < permissions.length; i++) {
      permissions[i].slug === "Parâmetros de contrato" &&
        arrayPermissions.push(2);
      permissions[i].slug === "Adicionar contrato" && arrayPermissions.push(3);
      permissions[i].slug === "Tranches" && arrayPermissions.push(4);
      permissions[i].slug === "Empresas" && arrayPermissions.push(5);
      permissions[i].slug === "Usuários" && arrayPermissions.push(6);
      permissions[i].slug === "Perfis de acesso" && arrayPermissions.push(7);
      permissions[i].slug === "Credenciais CCEE" && arrayPermissions.push(8);
      permissions[i].slug === "Parâmetros do sistemas" &&
        arrayPermissions.push(9);
      permissions[i].slug === "Gestão de feriados" && arrayPermissions.push(10);
      permissions[i].slug === "Parâmetros do Protheus" &&
        arrayPermissions.push(11);
      permissions[i].slug === "PLD" && arrayPermissions.push(12);
      permissions[i].slug === "Atualização por Período" &&
        arrayPermissions.push(13);
      permissions[i].slug === "Índices" && arrayPermissions.push(14);
      permissions[i].slug === "Reajuste de Contrato" &&
        arrayPermissions.push(15);
      permissions[i].slug === "Sazonalidade e Modulação" &&
        arrayPermissions.push(16);
    }

    setSwitchEdit(arrayPermissions);
  };

  const apiEditProfile = async () => {
    if (profileSelected?.name) {
      dispatch(loadingFullActive(true));
      setOpenModalEdit(false);

      await api
        .put(`/profiles/${profileSelected.id}`, {
          name: profileSelected?.name,
          permissions: switchEdit || 1,
        })
        .then((response) => {
          dispatch(
            snackbarActive({
              type: "success",
              message:
                response?.data?.message || "Perfil atualizado com sucesso!",
            })
          );

          getDataApiProfiles(page);
        })
        .catch((error) => {
          dispatch(
            snackbarActive({
              type: "error",
              message:
                error?.response?.data?.message || "Tente novamente mais tarde!",
            })
          );
        })
        .finally(() => {
          dispatch(loadingFullActive(false));
        });
    }
  };

  const apiCreateProfile = async (data) => {
    dispatch(loadingFullActive(true));

    if (dataCreatePermissions.length > 0) {
      const profile = {
        name: data.nome,
        permissions: dataCreatePermissions,
      };

      await api
        .post(`/profiles`, profile)
        .then((response) => {
          dispatch(
            snackbarActive({
              type: "success",
              message:
                response?.data?.message || "Perfil foi adicionado com sucesso!",
            })
          );

          getDataApiProfiles(page);
          reset();
          setDataCreatePermissions([]);
        })
        .catch((error) => {
          dispatch(
            snackbarActive({
              type: "error",
              message:
                error?.response?.data?.message || "Tente novamente mais tarde!",
            })
          );
        })
        .finally(() => {
          dispatch(loadingFullActive(false));
        });
    }
  };

  const alterSwitch = (e, id) => {
    let arrayPermissions = switchEdit;

    if (e.target.checked) {
      arrayPermissions.push(id);
    } else {
      arrayPermissions = arrayPermissions.filter((value) => {
        return value !== id;
      });
    }

    setSwitchEdit([...arrayPermissions]);
  };

  const alterCreateSwitch = (e, id) => {
    let arrayPermissions = dataCreatePermissions;

    if (e.target.checked) {
      arrayPermissions.push(id);
    } else {
      arrayPermissions = arrayPermissions.filter((value) => {
        return value !== id;
      });
    }

    setDataCreatePermissions([...arrayPermissions]);
  };

  async function getDataApiProfiles(activePage) {
    setLoadingTable(true);
    await api
      .get(`/profiles`, { params: { page: activePage || page } })
      .then((response) => {
        setLastPage(response.data.last_page);

        let list = response.data.data;
        let newList = [];

        for (let i = 0; i < list.length; i++) {
          newList.push({
            id: list[i]["id"],
            nome: list[i]["name"],
            acoes: (
              <div style={{ display: "flex" }}>
                <BtnAction
                  style={{ marginRight: 10 }}
                  onClick={() => handleModalView(list[i])}
                >
                  Ver permissões
                </BtnAction>
                <BtnAction
                  style={{ marginRight: 10 }}
                  onClick={() => handleModalEdit(list[i])}
                >
                  Alterar
                </BtnAction>
              </div>
            ),
          });
        }

        setContentTable(newList);
      })
      .catch((error) => {
        dispatch(
          snackbarActive({
            type: "error",
            message:
              error?.response?.data?.message || "Tente novamente mais tarde!",
          })
        );
      })
      .finally(() => {
        setLoadingTable(false);
      });
  }

  useEffect(() => {
    getDataApiProfiles();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MainTemplate title="Adicionar perfil de acesso">
        <Card>
          <Grid container spacing={2}>
            <Grid item md={3} sm={6} xs={12}>
              <Label text="Nome" />

              <Controller
                name="nome"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field, ref }) => (
                  <Input
                    {...field}
                    ref={ref}
                    error={errors.nome ? true : false}
                    fullWidth
                    placeholder="Digite o nome do perfil"
                    variant="outlined"
                  />
                )}
              />

              {errors.nome && <MessageError>Campo necessário</MessageError>}
            </Grid>
          </Grid>

          <Grid mt={{ md: 0, xs: 2 }} container spacing={{ md: 2, xs: 0 }}>
            <Grid item md={3}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      onChange={(e) => alterCreateSwitch(e, 2)}
                      checked={dataCreatePermissions.includes(2)}
                    />
                  }
                  label="Parâmetros contratos"
                />

                <FormControlLabel
                  control={
                    <Switch
                      onChange={(e) => alterCreateSwitch(e, 3)}
                      checked={dataCreatePermissions.includes(3)}
                    />
                  }
                  label="Adicionar contrato"
                />

                <FormControlLabel
                  control={
                    <Switch
                      onChange={(e) => alterCreateSwitch(e, 4)}
                      checked={dataCreatePermissions.includes(4)}
                    />
                  }
                  label="Tranches"
                />

                <FormControlLabel
                  control={
                    <Switch
                      onChange={(e) => alterCreateSwitch(e, 5)}
                      checked={dataCreatePermissions.includes(5)}
                    />
                  }
                  label="Empresas"
                />

                <FormControlLabel
                  control={
                    <Switch
                      onChange={(e) => alterCreateSwitch(e, 6)}
                      checked={dataCreatePermissions.includes(6)}
                    />
                  }
                  label="Usuários"
                />
              </FormGroup>
            </Grid>
            <Grid item md={3}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      onChange={(e) => alterCreateSwitch(e, 7)}
                      checked={dataCreatePermissions.includes(7)}
                    />
                  }
                  label="Perfis de acesso"
                />

                <FormControlLabel
                  control={
                    <Switch
                      onChange={(e) => alterCreateSwitch(e, 8)}
                      checked={dataCreatePermissions.includes(8)}
                    />
                  }
                  label="Credenciais CCEE"
                />

                <FormControlLabel
                  control={
                    <Switch
                      onChange={(e) => alterCreateSwitch(e, 9)}
                      checked={dataCreatePermissions.includes(9)}
                    />
                  }
                  label="Parâmetros sistemas"
                />

                <FormControlLabel
                  control={
                    <Switch
                      onChange={(e) => alterCreateSwitch(e, 10)}
                      checked={dataCreatePermissions.includes(10)}
                    />
                  }
                  label="Gestão feriados"
                />

                <FormControlLabel
                  control={
                    <Switch
                      onChange={(e) => alterCreateSwitch(e, 11)}
                      checked={dataCreatePermissions.includes(11)}
                    />
                  }
                  label="Parâmetros do Protheus"
                />
              </FormGroup>
            </Grid>
            <Grid item md={3}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      onChange={(e) => alterCreateSwitch(e, 12)}
                      checked={dataCreatePermissions.includes(12)}
                    />
                  }
                  label="PLD"
                />
              </FormGroup>

              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      onChange={(e) => alterCreateSwitch(e, 13)}
                      checked={dataCreatePermissions.includes(13)}
                    />
                  }
                  label="Atualização por Período"
                />
              </FormGroup>

              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      onChange={(e) => alterCreateSwitch(e, 14)}
                      checked={dataCreatePermissions.includes(14)}
                    />
                  }
                  label="Índices"
                />
              </FormGroup>

              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      onChange={(e) => alterCreateSwitch(e, 15)}
                      checked={dataCreatePermissions.includes(15)}
                    />
                  }
                  label="Reajuste de Contrato"
                />
              </FormGroup>

              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      onChange={(e) => alterCreateSwitch(e, 16)}
                      checked={dataCreatePermissions.includes(16)}
                    />
                  }
                  label="Sazonalidade e Modulação"
                />
              </FormGroup>
            </Grid>
          </Grid>

          <Button
            text="Adicionar"
            onClick={handleSubmit(apiCreateProfile)}
            sx={{ mt: 4, mb: 1 }}
          />
        </Card>

        <TitleList>Perfis de acesso</TitleList>

        <Card style={{ padding: 0 }}>
          <Table header={headerTable} loading={loadingTable}>
            {contentTable?.length > 0 &&
              contentTable?.map((item) => (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.nome}</td>
                  <td>{item.acoes}</td>
                </tr>
              ))}
          </Table>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 15,
              paddingBottom: 10,
            }}
          >
            <Pagination
              count={lastPage}
              page={page}
              onChange={(event, value) => handlePagination(value)}
            />
          </div>
        </Card>
      </MainTemplate>

      <MainModal open={openModalView} setOpen={setOpenModalView}>
        <h2 style={{ margin: 0 }}>Visualização Perfil</h2>

        <p>Permissões que esse perfil tem acesso!</p>

        {profileSelected?.permissions?.length > 0 ? (
          profileSelected?.permissions?.map((item) => (
            <p key={item.permission} style={{ color: "#636363" }}>
              * {item.permission}
            </p>
          ))
        ) : (
          <p style={{ color: "#636363" }}>Esse perfil não tem nenhum acesso!</p>
        )}

        <ModalButtonArea>
          <button onClick={() => setOpenModalView(false)}>Ok</button>
        </ModalButtonArea>
      </MainModal>

      <MainModal open={openModalEdit} setOpen={setOpenModalEdit}>
        <h2 style={{ margin: 0 }}>Editar Perfil</h2>

        <Grid mt={1} mb={2} container spacing={2}>
          <Grid item md={8} xs={12}>
            <Label text="Nome" />

            <Input
              id="nome"
              error={!profileSelected?.name ? true : false}
              value={profileSelected?.name}
              onChange={(e) =>
                setProfileSelected((profileSelected) => ({
                  ...profileSelected,
                  name: e.target.value,
                }))
              }
              fullWidth
              placeholder="Digite o nome desse perfil"
            />

            {!profileSelected?.name && (
              <MessageError>Campo necessário</MessageError>
            )}
          </Grid>
        </Grid>

        <FormGroup>
          <Grid container spacing={{ md: 2, xs: 0 }}>
            <Grid item md={6} xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    onChange={(e) => alterSwitch(e, 2)}
                    checked={switchEdit.includes(2)}
                  />
                }
                label="Parâmetros contratos"
              />

              <FormControlLabel
                control={
                  <Switch
                    onChange={(e) => alterSwitch(e, 3)}
                    checked={switchEdit.includes(3)}
                  />
                }
                label="Adicionar contrato"
              />

              <FormControlLabel
                control={
                  <Switch
                    onChange={(e) => alterSwitch(e, 4)}
                    checked={switchEdit.includes(4)}
                  />
                }
                label="Tranches"
              />

              <FormControlLabel
                control={
                  <Switch
                    onChange={(e) => alterSwitch(e, 5)}
                    checked={switchEdit.includes(5)}
                  />
                }
                label="Empresas"
              />

              <FormControlLabel
                control={
                  <Switch
                    onChange={(e) => alterSwitch(e, 6)}
                    checked={switchEdit.includes(6)}
                  />
                }
                label="Usuários"
              />

              <FormControlLabel
                control={
                  <Switch
                    onChange={(e) => alterSwitch(e, 7)}
                    checked={switchEdit.includes(7)}
                  />
                }
                label="Perfis de acesso"
              />

              <FormControlLabel
                control={
                  <Switch
                    onChange={(e) => alterSwitch(e, 8)}
                    checked={switchEdit.includes(8)}
                  />
                }
                label="Credenciais CCEE"
              />

              <FormControlLabel
                control={
                  <Switch
                    onChange={(e) => alterSwitch(e, 9)}
                    checked={switchEdit.includes(9)}
                  />
                }
                label="Parâmetros sistemas"
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    onChange={(e) => alterSwitch(e, 10)}
                    checked={switchEdit.includes(10)}
                  />
                }
                label="Gestão feriados"
              />

              <FormControlLabel
                control={
                  <Switch
                    onChange={(e) => alterSwitch(e, 11)}
                    checked={switchEdit.includes(11)}
                  />
                }
                label="Parâmetros do Protheus"
              />

              <FormControlLabel
                control={
                  <Switch
                    onChange={(e) => alterSwitch(e, 12)}
                    checked={switchEdit.includes(12)}
                  />
                }
                label="PLD"
              />

              <FormControlLabel
                control={
                  <Switch
                    onChange={(e) => alterSwitch(e, 13)}
                    checked={switchEdit.includes(13)}
                  />
                }
                label="Atualização por Período"
              />

              <FormControlLabel
                control={
                  <Switch
                    onChange={(e) => alterSwitch(e, 14)}
                    checked={switchEdit.includes(14)}
                  />
                }
                label="Índices"
              />

              <FormControlLabel
                control={
                  <Switch
                    onChange={(e) => alterSwitch(e, 15)}
                    checked={switchEdit.includes(15)}
                  />
                }
                label="Reajuste de Contrato"
              />

              <FormControlLabel
                control={
                  <Switch
                    onChange={(e) => alterSwitch(e, 16)}
                    checked={switchEdit.includes(16)}
                  />
                }
                label="Sazonalidade e Modulação"
              />
            </Grid>
          </Grid>
        </FormGroup>

        <ModalButtonArea>
          <button onClick={() => apiEditProfile()}>Editar</button>
          <button onClick={() => setOpenModalEdit(false)}>Cancelar</button>
        </ModalButtonArea>
      </MainModal>
    </>
  );
}
