import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import CachedIcon from "@mui/icons-material/Cached";
import { Fab, Grid, MenuItem, Pagination } from "@mui/material";
import NumberFormat from "react-number-format";

import Card from "../../components/Card";
import MainTemplate from "../../components/MainTemplate";
import { Footer, BtnAction, ModalButtonArea } from "./styled";
import Table from "../../components/Table";
import MainModal from "../../components/MainModal";
import SelectInput from "../../components/SelectInput";
import Label from "../../components/Label";
import api from "../../services/api";
import Input from "../../components/Input";
import { loadingFullActive } from "../../store/modules/loadingFull/action";
import { snackbarActive } from "../../store/modules/snackbar/action";

export default function Companies() {
  const dispatch = useDispatch();

  const [contentSelectTranches, setContentSelectTranches] = useState([]);

  const [trancheId, setTrancheId] = useState("");
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [contentTable, setContentTable] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState({
    id: null,
    name: null,
  });
  const [search, setSearch] = useState("");
  const headerTable = [
    "ID",
    "Nome",
    "Tranche",
    "Interna/Externa",
    "CNPJ",
    "País",
    "UF",
    "Cidade",
    "Endereço",
  ];

  const openModal = ({ ID, Nome }) => {
    setCompany({
      id: ID,
      name: Nome,
    });

    setOpen(true);
  };

  const getDataApiCompanies = async (activePage) => {
    setLoading(true);

    await api
      .get(`/companies`, {
        params: { page: activePage || page, q: search || null },
      })
      .then((response) => {
        setLastPage(response.data.last_page);
        let list = response.data.data;
        for (let i = 0; i < list.length; i++) {
          list[i]["CNPJ"] = (
            <NumberFormat
              value={list[i]["CNPJ"]}
              displayType={"text"}
              format={"##.###.###/####-##"}
              renderText={(value, props) => value}
            />
          );

          list[i]["Tranche"] = !list[i]["Tranche"] ? (
            <BtnAction
              onClick={() => {
                openModal(list[i]);
              }}
            >
              Vincular tranche
            </BtnAction>
          ) : (
            list[i]["Tranche"]
          );
        }
        setContentTable(list);
      })
      .catch((error) => {
        dispatch(
          snackbarActive({
            type: "error",
            message:
              error?.response?.data?.message || "Tente novamente mais tarde!",
          })
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getApiContentSelectTranches = async () => {
    dispatch(loadingFullActive(true));

    await api
      .get("/tranches?no-pagination")
      .then((response) => {
        setContentSelectTranches(response.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(loadingFullActive(false));
      });
  };

  const apiReloadCompanies = async () => {
    dispatch(loadingFullActive(true));

    await api
      .get("/companies/refresh")
      .then((response) => {
        setLastPage(response.data.last_page);
        let list = response.data.data;
        for (let i = 0; i < list.length; i++) {
          list[i]["Tranche"] = !list[i]["Tranche"] ? (
            <BtnAction
              onClick={() => {
                openModal(list[i]);
              }}
            >
              Vincular tranche
            </BtnAction>
          ) : (
            list[i]["Tranche"]
          );
        }
        setContentTable(list);
      })
      .catch((error) => {
        dispatch(
          snackbarActive({
            type: "error",
            message:
              error?.response?.data?.message || "Tente novamente mais tarde!",
          })
        );
      })
      .finally(() => {
        dispatch(loadingFullActive(false));
      });
  };

  const associateCompanyWithTranche = async () => {
    if (trancheId) {
      dispatch(loadingFullActive(true));

      await api
        .put(`/companies/${company.id}/tranche`, { tranche_id: trancheId })
        .then((response) => {
          getDataApiCompanies(page);
          setTrancheId("");

          dispatch(
            snackbarActive({
              type: "success",
              message:
                response?.data?.message || "Tranche vinculado com sucesso!",
            })
          );
        })
        .catch((error) => {
          dispatch(
            snackbarActive({
              type: "error",
              message:
                error?.response?.data?.message ||
                "Tranche vinculado com sucesso!",
            })
          );
        })
        .finally(() => {
          dispatch(loadingFullActive(false));
          setOpen(false);
        });
    }
  };

  const handlePagination = (page) => {
    setPage(page);
    getDataApiCompanies(page);
  };

  useEffect(() => {
    getDataApiCompanies();
    getApiContentSelectTranches();
  }, []);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    handlePagination(1);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MainTemplate title="Empresas">
        <Card style={{ padding: 0, marginBottom: 150 }}>
          <Grid px={2} pt={2} mb={2} container>
            <Grid item md={4} sm={6} xs={12}>
              <Label text="Código do contrato" />

              <Input
                value={search}
                onChange={handleSearch}
                placeholder="Buscar por nome ou CNPJ"
                fullWidth
              />
            </Grid>
          </Grid>

          <Table header={headerTable} loading={loading}>
            {contentTable?.length > 0 &&
              contentTable?.map((item) => (
                <tr key={item["ID"]}>
                  <td>{item["ID"]}</td>
                  <td>{item["Nome"]}</td>
                  <td>{item["Tranche"]}</td>
                  <td>{item["Interna"]}</td>
                  <td>{item["CNPJ"]}</td>
                  <td>{item["País"]}</td>
                  <td>{item["UF"]}</td>
                  <td>{item["Cidade"]}</td>
                  <td>{item["Endereço"]}</td>
                </tr>
              ))}
          </Table>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 10,
              paddingBottom: 20,
            }}
          >
            <Pagination
              count={lastPage}
              page={page}
              onChange={(event, value) => handlePagination(value)}
            />
          </div>
        </Card>

        <Footer>
          <Fab
            onClick={() => apiReloadCompanies()}
            sx={{
              textTransform: "none",
              backgroundColor: "#DA45F8",
              color: "white",
            }}
            variant="extended"
            color="primary"
            aria-label="add"
          >
            Atualizar Empresas
            <CachedIcon sx={{ paddingLeft: 1 }} />
          </Fab>
        </Footer>

        <MainModal width={450} open={open} setOpen={setOpen}>
          <h2 style={{ margin: 0 }}>Vincular Tranche</h2>
          <h5>
            ID: {company.id}
            <br />
            Nome: {company.name}
          </h5>

          <SelectInput
            value={trancheId}
            onChange={(e) => setTrancheId(e.target.value)}
            fullWidth
            displayEmpty
          >
            <MenuItem value="">
              <em>Selecione</em>
            </MenuItem>
            {contentSelectTranches?.length > 0 &&
              contentSelectTranches?.map((value) => (
                <MenuItem
                  key={value["Código tranche"]}
                  value={value["Código tranche"]}
                >
                  {value.Nome}
                </MenuItem>
              ))}
          </SelectInput>

          <ModalButtonArea>
            <button onClick={() => associateCompanyWithTranche()}>
              Salvar
            </button>
            <button onClick={() => setOpen(false)}>Cancelar</button>
          </ModalButtonArea>
        </MainModal>
      </MainTemplate>
    </>
  );
}
