import { useEffect, useState } from "react";
import { Grid, MenuItem } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import Input from "../../../../components/Input";
import Label from "../../../../components/Label";
import MainModal from "../../../../components/MainModal";
import SelectInput from "../../../../components/SelectInput";
import api from "../../../../services/api";
import { MessageError, ModalButtonArea } from "./styled";
import { loadingFullActive } from "../../../../store/modules/loadingFull/action";
import { snackbarActive } from "../../../../store/modules/snackbar/action";

export default function ModalEdit({
  openModal,
  setOpenModal,
  user,
  selectProfile,
  reloadList,
}) {
  const {
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    if (user.id) {
      setValue("name", user.nome);
      setValue("email", user.email);
      setValue("profile", user.perfis_id);
    }
  }, [user, setValue]);

  const apiUpdateUser = async (data) => {
    dispatch(loadingFullActive(true));

    const userUpdateData = {
      name: data.name,
      email: data.email,
      profiles_id: data.profile,
    };

    await api
      .put(`/users/${user.id}`, userUpdateData)
      .then((response) => {
        dispatch(
          snackbarActive({
            type: "success",
            message: response?.data?.message || "Usuário alterado com sucesso!",
          })
        );

        reset();
        setOpenModal(false);
        reloadList();
      })
      .catch((error) => {
        dispatch(
          snackbarActive({
            type: "error",
            message:
              error?.response?.data?.message || "Tente novamente mais tarde!",
          })
        );
      })
      .finally(() => {
        dispatch(loadingFullActive(false));
      });
  };

  return (
    <>
      <MainModal open={openModal} setOpen={setOpenModal}>
        <>
          <h2 style={{ margin: 0 }}>Editar Usuário</h2>

          <Grid mt={2} container spacing={2}>
            <Grid item md={6} xs={6}>
              <Label text="Nome Completo" />

              <Controller
                name="name"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field, ref }) => (
                  <Input
                    {...field}
                    ref={ref}
                    error={errors.name ? true : false}
                    fullWidth
                    type="text"
                    placeholder="Digite seu nome"
                    variant="outlined"
                  />
                )}
              />

              {errors.name && <MessageError>Campo necessário</MessageError>}
            </Grid>

            <Grid item md={6} xs={6}>
              <Label text="Email" />

              <Controller
                name="email"
                control={control}
                rules={{
                  required: "Campo necessário",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Email inválido",
                  },
                }}
                defaultValue=""
                render={({ field, ref }) => (
                  <Input
                    {...field}
                    ref={ref}
                    error={errors.email ? true : false}
                    fullWidth
                    type="email"
                    placeholder="Digite seu email"
                    variant="outlined"
                  />
                )}
              />

              {errors.email && (
                <MessageError>{errors.email.message}</MessageError>
              )}
            </Grid>
          </Grid>

          <Grid mt={1} container spacing={2}>
            <Grid item md={6} xs={6}>
              <Label text="Perfil de acesso" />

              <Controller
                name="profile"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field, ref }) => (
                  <SelectInput
                    {...field}
                    ref={ref}
                    error={errors.profile ? true : false}
                    fullWidth
                    displayEmpty
                  >
                    <MenuItem value="">
                      <em>Selecione</em>
                    </MenuItem>
                    {selectProfile.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </SelectInput>
                )}
              />

              {errors.profile && <MessageError>Campo necessário</MessageError>}
            </Grid>
          </Grid>

          <ModalButtonArea>
            <button onClick={handleSubmit(apiUpdateUser)}>Editar</button>
            <button onClick={() => setOpenModal(false)}>Cancelar</button>
          </ModalButtonArea>
        </>
      </MainModal>
    </>
  );
}
