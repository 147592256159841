import styled from "styled-components";
import { TextField } from "@mui/material";

export const InputPattern = styled(TextField)`
  input {
    font-size: 14px;
  }

  div {
    height: 50px;
    border-radius: 55px !important;
  }

  fieldset {
    border-radius: 55px !important;
  }
`;
