import styled from "styled-components";

export const BtnAction = styled.span`
  background-color: #da45f8;
  color: white;
  border-radius: 50px;
  padding: 0.5em 1em;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-flex;
  width: max-content;

  :hover {
    opacity: 0.6;
  }

  :active {
    outline: 1px solid lightgrey;
  }
`;

export const TitleList = styled.h1`
  @media (max-width: 468px) {
    padding-left: 0.5em;
  }
`;

export const Footer = styled.footer`
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 1em;
  z-index: 2;
`;
