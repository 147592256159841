import styled from "styled-components";

export const Title = styled.h5` 
    font-size: 20px;
    color: #000000;
    margin: 0;
`;

export const Description = styled.p` 
    color: grey;
    font-size: 14px;
    margin: 0;
    line-height: 1.5em;
    padding-top: .5em;
`;