import { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch } from "react-redux";
import { Autocomplete, Grid, MenuItem, TextField } from "@mui/material";
import { Controller } from "react-hook-form";

import { Step } from "./styled";
import api from "../../../../services/api";
import MessageError from "../../../../components/MessageError";
import Label from "../../../../components/Label";
import SelectInput from "../../../../components/SelectInput";
import Button from "../../../../components/Button";
import Input from "../../../../components/Input";
import { loadingFullActive } from "../../../../store/modules/loadingFull/action";

export default function StepDadosGerais({
  step,
  setStep,
  control,
  errors,
  watch,
  setTypeProduct,
  handleSubmit,
  enableEdit,
  setEnableEdit,
  onSubmit,
}) {
  const dispatch = useDispatch();

  const [step1Selects, setStep1Selects] = useState({
    fatoContrato: [],
    pontaA: [],
    pontaB: [],
    flags: [],
    submercados: [],
    tipoOperacao: [],
  });

  const [inputValue, setInputValue] = useState("");
  const [inputValuePontaA, setInputValuePontaA] = useState("");

  const loadSelectsStep1 = async () => {
    dispatch(loadingFullActive(true));

    await api
      .get("/contracts/general")
      .then((response) => {
        setStep1Selects({
          fatoContrato: response.data["fato-contrato"],
          pontaA: response.data["ponta-a"],
          pontaB: response.data["ponta-b"],
          flags: response.data["flags"],
          submercados: response.data["submercados"],
          tipoOperacao: response.data["operacoes"],
        });
      })
      .finally(() => {
        dispatch(loadingFullActive(false));
      });
  };

  const verifyTypeProduct = () => {
    const fatoContrato = step1Selects?.fatoContrato?.find((item) => {
      return Number(item.value) === Number(watch("fatoContrato"));
    });

    if (watch("pontaA")?.interna === 1 && watch("pontaB")?.interna === 1) {
      setTypeProduct("Both");
    } else {
      if (String(fatoContrato?.label) === "Venda") setTypeProduct("Sell");

      if (String(fatoContrato?.label) === "Compra") setTypeProduct("Buy");
    }
  };

  const onSubmitForm1 = (data) => setStep(2);

  useEffect(() => {
    verifyTypeProduct();
  }, [watch("fatoContrato"), watch("pontaB")]);

  useEffect(() => {
    loadSelectsStep1();
  }, []);

  return (
    <>
      <>
        <Step ativo={step > 1 ? true : false}>
          {step > 1 ? (
            <h3 style={{ marginTop: "4px" }}>DADOS GERAIS</h3>
          ) : (
            <h4 style={{ marginTop: "4px" }}>DADOS GERAIS</h4>
          )}

          {step > 1 && step !== 5 && <span>Completo</span>}

          {step === 5 && !enableEdit && (
            <button onClick={() => setEnableEdit(1)}>
              <span>Editar seção</span>
              <EditIcon />
            </button>
          )}

          {enableEdit === 1 && (
            <button onClick={handleSubmit(onSubmit)}>
              <span>Salvar seção</span>
              <EditIcon />
            </button>
          )}
        </Step>

        {(step === 1 || step === 5) && (
          <>
            <Grid mt={2} container spacing={2}>
              <Grid item md={4} xs={12}>
                <Label text="Título do contrato" />

                <Controller
                  name="titulo"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: false,
                  }}
                  render={({ field, ref }) => (
                    <Input
                      {...field}
                      ref={ref}
                      id="titulo"
                      error={errors.titulo ? true : false}
                      disabled={step === 1 || enableEdit === 1 ? false : true}
                      fullWidth
                      type="text"
                      placeholder="Digite o título do contrato"
                    />
                  )}
                />

                {errors.titulo && <MessageError>Campo necessário</MessageError>}
              </Grid>
            </Grid>

            <Grid mt={0} container spacing={2}>
              <Grid item md={2} xs={6}>
                <Label text="Fato do contrato" />

                <Controller
                  name="fatoContrato"
                  control={control}
                  rules={{ required: true }}
                  defaultValue=""
                  render={({ field: { value, onChange, onBlur }, ref }) => (
                    <SelectInput
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      ref={ref}
                      error={errors.fatoContrato ? true : false}
                      disabled={step === 1 || enableEdit === 1 ? false : true}
                      fullWidth
                      displayEmpty
                    >
                      <MenuItem value="">
                        <em>Selecione</em>
                      </MenuItem>

                      {step1Selects?.fatoContrato?.length > 0 &&
                        step1Selects?.fatoContrato?.map((item) => (
                          <MenuItem key={item.value} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                    </SelectInput>
                  )}
                />

                {errors.fatoContrato && (
                  <MessageError>Campo necessário</MessageError>
                )}
              </Grid>

              <Grid item md={3} xs={6}>
                <Label text="Ponta A" />

                <Controller
                  name="pontaA"
                  control={control}
                  rules={{ required: true }}
                  defaultValue={null}
                  render={({ field: { value, onChange, onBlur }, ref }) => (
                    <Autocomplete
                      value={value}
                      disabled={step === 1 || enableEdit === 1 ? false : true}
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      inputValue={inputValuePontaA}
                      onInputChange={(event, newInputValue) => {
                        setInputValuePontaA(newInputValue);
                      }}
                      id="controllable-states-demo"
                      noOptionsText="Não foi encontrado"
                      options={step1Selects?.pontaA}
                      sx={{
                        width: "100%",
                        ".MuiInputBase-root": {
                          height: "50px!important",
                        },
                        input: {
                          fontSize: "15px!important",
                        },
                        fieldset: { borderRadius: "100px" },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Selecione"
                          error={errors.pontaA ? true : false}
                        />
                      )}
                      getOptionLabel={(option) => option.label}
                    />
                  )}
                />

                {errors.pontaA && <MessageError>Campo necessário</MessageError>}
              </Grid>

              <Grid item md={3} xs={6}>
                <Label text="Ponta B" />

                <Controller
                  name="pontaB"
                  control={control}
                  rules={{ required: true }}
                  defaultValue={null}
                  render={({ field: { value, onChange, onBlur }, ref }) => (
                    <Autocomplete
                      value={value}
                      disabled={step === 1 || enableEdit === 1 ? false : true}
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      inputValue={inputValue}
                      onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                      }}
                      id="controllable-states-demo"
                      noOptionsText="Não foi encontrado"
                      options={step1Selects?.pontaB}
                      sx={{
                        width: "100%",
                        ".MuiInputBase-root": {
                          height: "50px!important",
                        },
                        input: {
                          fontSize: "15px!important",
                        },
                        fieldset: { borderRadius: "100px" },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Selecione"
                          error={errors.pontaB ? true : false}
                        />
                      )}
                      getOptionLabel={(option) => option.label}
                    />
                  )}
                />

                {errors.pontaB && <MessageError>Campo necessário</MessageError>}
              </Grid>
            </Grid>

            <Grid mt={0} mb={step === 5 ? 4 : 2} container spacing={2}>
              <Grid item md={2} xs={6}>
                <Label text="Flag" />

                <Controller
                  name="flag"
                  control={control}
                  rules={{ required: true }}
                  defaultValue=""
                  render={({ field: { value, onChange, onBlur }, ref }) => (
                    <SelectInput
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      ref={ref}
                      error={errors.flag ? true : false}
                      disabled={step === 1 || enableEdit === 1 ? false : true}
                      fullWidth
                      displayEmpty
                    >
                      <MenuItem value="">
                        <em>Selecione</em>
                      </MenuItem>
                      {step1Selects?.flags?.length > 0 &&
                        step1Selects?.flags?.map((item) => (
                          <MenuItem key={item.value} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                    </SelectInput>
                  )}
                />

                {errors.flag && <MessageError>Campo necessário</MessageError>}
              </Grid>

              <Grid item md={3} xs={6}>
                <Label text="Submercado" />

                <Controller
                  name="submercado"
                  control={control}
                  rules={{ required: true }}
                  defaultValue=""
                  render={({ field: { value, onChange, onBlur }, ref }) => (
                    <SelectInput
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      ref={ref}
                      error={errors.submercado ? true : false}
                      disabled={step === 1 || enableEdit === 1 ? false : true}
                      fullWidth
                      displayEmpty
                    >
                      <MenuItem value="">
                        <em>Selecione</em>
                      </MenuItem>

                      {step1Selects?.submercados?.length > 0 &&
                        step1Selects?.submercados?.map((item) => (
                          <MenuItem key={item.value} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                    </SelectInput>
                  )}
                />

                {errors.submercado && (
                  <MessageError>Campo necessário</MessageError>
                )}
              </Grid>

              <Grid item md={3} xs={6}>
                <Label text="Tipo de operação" />

                <Controller
                  name="tipoOperacao"
                  control={control}
                  rules={{ required: true }}
                  defaultValue=""
                  render={({ field: { value, onChange, onBlur }, ref }) => (
                    <SelectInput
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      ref={ref}
                      error={errors.tipoOperacao ? true : false}
                      disabled={step === 1 || enableEdit === 1 ? false : true}
                      fullWidth
                      displayEmpty
                    >
                      <MenuItem value="">
                        <em>Selecione</em>
                      </MenuItem>
                      {step1Selects?.tipoOperacao?.length > 0 &&
                        step1Selects?.tipoOperacao?.map((item) => (
                          <MenuItem key={item.value} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                    </SelectInput>
                  )}
                />

                {errors.tipoOperacao && (
                  <MessageError>Campo necessário</MessageError>
                )}
              </Grid>
            </Grid>

            {step !== 5 && (
              <Button
                onClick={handleSubmit(onSubmitForm1)}
                sx={{ my: 4 }}
                text="Prosseguir"
              />
            )}
          </>
        )}
      </>
    </>
  );
}
