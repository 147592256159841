import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SettingsIcon from "@mui/icons-material/Settings";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import GroupIcon from "@mui/icons-material/Group";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import BadgeIcon from "@mui/icons-material/Badge";
import EmojiObjectsIcon from "@mui/icons-material/EmojiObjects";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import ListIcon from "@mui/icons-material/List";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

import { Area, MenuDivider, Menu, IconArea, TextoArea } from "./styled";
import { useEffect } from "react";

export default function SideMenu() {
  const location = useLocation();
  const user = useSelector((state) => state.users);
  const isMenuMinimized = useSelector((state) => state.configs);

  useEffect(() => {
    let linkElement = document.getElementById(
      `linkAnchor__${location.pathname.replaceAll("/", "")}`
    );

    if (linkElement) {
      linkElement.scrollIntoView();
    }
  }, []);

  return (
    <Area isMenuMinimized={isMenuMinimized}>
      <MenuDivider isMenuMinimized={isMenuMinimized}>
        <h5>CONTRATO</h5>
      </MenuDivider>

      <Link to="/dashboard">
        <Menu
          isMenuMinimized={isMenuMinimized}
          active={location.pathname === "/dashboard" ? true : false}
        >
          <IconArea>
            <DashboardIcon />
          </IconArea>
          <TextoArea isMenuMinimized={isMenuMinimized}>
            <p>Dashboard</p>
          </TextoArea>
        </Menu>
      </Link>

      {user?.permissions?.includes("Parâmetros de contrato") && (
        <Link to="/parametros-contrato">
          <Menu
            isMenuMinimized={isMenuMinimized}
            active={location.pathname === "/parametros-contrato" ? true : false}
          >
            <IconArea>
              <SettingsIcon />
            </IconArea>
            <TextoArea isMenuMinimized={isMenuMinimized}>
              <p>Parâmetros contratos</p>
            </TextoArea>
          </Menu>
        </Link>
      )}

      {user?.permissions?.includes("Adicionar contrato") && (
        <Link to="/adicionar-contrato">
          <Menu
            isMenuMinimized={isMenuMinimized}
            active={location.pathname === "/adicionar-contrato" ? true : false}
          >
            <IconArea>
              <HistoryEduIcon />
            </IconArea>

            <TextoArea isMenuMinimized={isMenuMinimized}>
              <p>Adicionar contrato</p>
            </TextoArea>
          </Menu>
        </Link>
      )}

      {user?.permissions?.includes("PLD") && (
        <Link to="/plds">
          <Menu
            isMenuMinimized={isMenuMinimized}
            active={location.pathname === "/plds" ? true : false}
          >
            <IconArea>
              <CurrencyExchangeIcon />
            </IconArea>

            <TextoArea isMenuMinimized={isMenuMinimized}>
              <p>PLDs</p>
            </TextoArea>
          </Menu>
        </Link>
      )}

      {user?.permissions?.includes("Atualização por Período") && (
        <Link to="/atualizacao-periodo">
          <Menu
            isMenuMinimized={isMenuMinimized}
            active={location.pathname === "/atualizacao-periodo" ? true : false}
          >
            <IconArea>
              <EventRepeatIcon />
            </IconArea>

            <TextoArea isMenuMinimized={isMenuMinimized}>
              <p>Atualização por período</p>
            </TextoArea>
          </Menu>
        </Link>
      )}

      {user?.permissions?.includes("Índices") && (
        <Link to="/indices">
          <Menu
            isMenuMinimized={isMenuMinimized}
            active={location.pathname === "/indices" ? true : false}
          >
            <IconArea>
              <ListIcon />
            </IconArea>

            <TextoArea isMenuMinimized={isMenuMinimized}>
              <p>Índices</p>
            </TextoArea>
          </Menu>
        </Link>
      )}

      {user?.permissions?.includes("Reajuste de Contrato") && (
        <Link to="/reajuste-contrato">
          <Menu
            isMenuMinimized={isMenuMinimized}
            active={location.pathname === "/reajuste-contrato" ? true : false}
          >
            <IconArea>
              <AppRegistrationIcon />
            </IconArea>

            <TextoArea isMenuMinimized={isMenuMinimized}>
              <p>Reajuste de contrato</p>
            </TextoArea>
          </Menu>
        </Link>
      )}

      {user?.permissions?.includes("Sazonalidade e Modulação") && (
        <Link to="/sazonalidade-modulacao">
          <Menu
            isMenuMinimized={isMenuMinimized}
            active={
              location.pathname === "/sazonalidade-modulacao" ? true : false
            }
          >
            <IconArea>
              <AccessTimeIcon />
            </IconArea>

            <TextoArea isMenuMinimized={isMenuMinimized}>
              <p>Sazonalidade/Modulação</p>
            </TextoArea>
          </Menu>
        </Link>
      )}

      {["Tranches", "Empresas"].some((o) => user.permissions.includes(o)) && (
        <MenuDivider isMenuMinimized={isMenuMinimized}>
          <h5>EMPRESAS</h5>
        </MenuDivider>
      )}

      {user?.permissions?.includes("Tranches") && (
        <Link to="/tranches" id="linkAnchor__tranches">
          <Menu
            isMenuMinimized={isMenuMinimized}
            active={location.pathname === "/tranches" ? true : false}
          >
            <IconArea>
              <EmojiObjectsIcon />
            </IconArea>
            <TextoArea isMenuMinimized={isMenuMinimized}>
              <p>Tranches</p>
            </TextoArea>
          </Menu>
        </Link>
      )}

      {user?.permissions?.includes("Empresas") && (
        <Link to="/empresas" id="linkAnchor__empresas">
          <Menu
            isMenuMinimized={isMenuMinimized}
            active={location.pathname === "/empresas" ? true : false}
          >
            <IconArea>
              <SettingsIcon />
            </IconArea>
            <TextoArea isMenuMinimized={isMenuMinimized}>
              <p>Empresas</p>
            </TextoArea>
          </Menu>
        </Link>
      )}

      {["Usuários", "Perfis de acesso", "Credenciais CCEE"].some((o) =>
        user?.permissions?.includes(o)
      ) && (
        <MenuDivider isMenuMinimized={isMenuMinimized}>
          <h5>ACESSOS</h5>
        </MenuDivider>
      )}

      {user?.permissions?.includes("Usuários") && (
        <Link to="/usuarios" id="linkAnchor__usuarios">
          <Menu
            isMenuMinimized={isMenuMinimized}
            active={location.pathname === "/usuarios" ? true : false}
          >
            <IconArea>
              <GroupIcon />
            </IconArea>

            <TextoArea isMenuMinimized={isMenuMinimized}>
              <p>Usuários</p>
            </TextoArea>
          </Menu>
        </Link>
      )}

      {user?.permissions?.includes("Perfis de acesso") && (
        <Link to="/perfis-acesso" id="linkAnchor__perfis-acesso">
          <Menu
            isMenuMinimized={isMenuMinimized}
            active={location.pathname === "/perfis-acesso" ? true : false}
          >
            <IconArea>
              <AdminPanelSettingsIcon />
            </IconArea>
            <TextoArea isMenuMinimized={isMenuMinimized}>
              <p>Perfis acesso</p>
            </TextoArea>
          </Menu>
        </Link>
      )}

      {user?.permissions?.includes("Credenciais CCEE") && (
        <Link to="/ccee" id="linkAnchor__ccee">
          <Menu
            isMenuMinimized={isMenuMinimized}
            active={location.pathname === "/ccee" ? true : false}
          >
            <IconArea>
              <BadgeIcon />
            </IconArea>
            <TextoArea isMenuMinimized={isMenuMinimized}>
              <p>Credenciais CCEE</p>
            </TextoArea>
          </Menu>
        </Link>
      )}

      {[
        "Parâmetros do sistema",
        "Gestão de feriados",
        "Parâmetros do Protheus",
      ].some((o) => user.permissions.includes(o)) && (
        <MenuDivider isMenuMinimized={isMenuMinimized}>
          <h5>SISTEMA</h5>
        </MenuDivider>
      )}

      {user?.permissions?.includes("Parâmetros do sistemas") && (
        <Link to="/parametros-sistema" id="linkAnchor__parametros-sistema">
          <Menu
            isMenuMinimized={isMenuMinimized}
            active={location.pathname === "/parametros-sistema" ? true : false}
          >
            <IconArea>
              <SettingsIcon />
            </IconArea>
            <TextoArea isMenuMinimized={isMenuMinimized}>
              <p>Parâmetros sistema</p>
            </TextoArea>
          </Menu>
        </Link>
      )}

      {user?.permissions?.includes("Gestão de feriados") && (
        <Link to="/gestao-feriados" id="linkAnchor__gestao-feriados">
          <Menu
            isMenuMinimized={isMenuMinimized}
            active={location.pathname === "/gestao-feriados" ? true : false}
          >
            <IconArea>
              <CalendarMonthIcon />
            </IconArea>

            <TextoArea isMenuMinimized={isMenuMinimized}>
              <p>Gestão feriados</p>
            </TextoArea>
          </Menu>
        </Link>
      )}

      {/* {user.permissions.includes('Parâmetros do Protheus') &&
                <Link to="/parametros-protheus">
                    <Menu active={location.pathname === "/parametros-protheus" ? true : false}>
                        <IconArea>
                            <SettingsIcon />
                        </IconArea>
                        <TextoArea>
                            <p>Parâmetros Protheus</p>
                        </TextoArea>
                    </Menu>
                </Link>
            } */}
    </Area>
  );
}
