import styled from "styled-components";

export const ModalButtonArea = styled.div` 
    gap: 10px;
    display: flex;
    justify-content: flex-end;
    margin-top: 1.7em;

    button{
        background: grey;
        border: 0;
        color: white;
        font-size: 14px;
        border-radius: 6px;
        padding: .8em 1.6em;

        :hover{
            opacity: .6;
        }

        :active{
            outline: 3px solid lightgrey;
        }

        :first-child{
            background: var(--main-color);
        }
    }
`;

export const MessageError = styled.span` 
    color: #d32f2f;
    font-size: 12px;
`;