import { useEffect, useState } from "react";
import {
  Divider,
  Grid,
  IconButton,
  Step,
  StepLabel,
  Stepper,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import MainModal from "../../../../components/MainModal";
import api from "../../../../services/api";
import { Description, Title } from "./styled";
import { useDispatch } from "react-redux";
import { snackbarActive } from "../../../../store/modules/snackbar/action";

export default function ModalOrderBuy({ open, setOpen, contractId }) {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  const [orderBuy, setOrderBuy] = useState({
    buyNumber: "",
    codInterno: "",
    history: [],
    saleNumber: "",
  });

  const apiGetOrderBuy = async () => {
    api
      .get(`/contracts/${contractId}/history`)
      .then((response) => {
        setOrderBuy({
          buyNumber: response.data["buy_number"],
          codInterno: response.data["cod_interno"],
          history: response.data["history"],
          saleNumber: response.data["sale_number"],
          nf: response.data["nf"],
        });
      })
      .catch((error) => {
        dispatch(
          snackbarActive({
            type: "error",
            message:
              error?.response?.data?.message || "Tente novamente mais tarde!",
          })
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (open) {
      setLoading(true);
      apiGetOrderBuy();
    }
  }, [contractId]);

  if (loading) {
    return (
      <>
        <MainModal open={open} setOpen={setOpen}>
          <p style={{ textAlign: "center", color: "grey" }}>carregando...</p>
        </MainModal>
      </>
    );
  }

  return (
    <>
      <MainModal open={open} setOpen={setOpen}>
        <Grid container spacing={0} alignSelf="center" alignItems="center">
          <Grid mt={0} item md={6} sx={{ paddingTop: 0 }}>
            <p style={{ margin: 0 }}>
              <b>Cod interno</b>: {orderBuy.codInterno}
            </p>
          </Grid>

          <Grid
            item
            md={6}
            sx={{ paddingTop: 0 }}
            textAlign="end"
            alignSelf="center"
            alignItems="center"
          >
            <Tooltip disableInteractive>
              <IconButton onClick={() => setOpen(false)} title="Fechar">
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>

        <Divider sx={{ paddingTop: 2 }} />

        <Grid container spacing={0}>
          <Grid item md={6} alignSelf="center">
            <Stepper
              sx={{
                marginTop: 3,
                maxHeight: "300px",
                overflow: "scroll",
              }}
              activeStep={orderBuy?.history?.length + 1}
              orientation="vertical"
            >
              {orderBuy?.history?.length > 0 &&
                orderBuy?.history?.map((step, index) => (
                  <Step
                    sx={{
                      "& .MuiSvgIcon-root": {
                        color: "var(--main-color)",
                      },
                    }}
                    key={index}
                  >
                    <StepLabel
                      optional={
                        <Typography variant="caption">
                          {step["created_at"]}
                        </Typography>
                      }
                    >
                      {step.status}
                    </StepLabel>
                  </Step>
                ))}
            </Stepper>
          </Grid>
          <Grid item md={6} textAlign="end" alignSelf="center">
            <Title style={{ marginTop: 15 }}>Pedido de compra</Title>
            <Description>{orderBuy.buyNumber}</Description>

            <Title style={{ marginTop: 30 }}>Pedido de venda</Title>
            <Description>{orderBuy.saleNumber}</Description>

            <Title style={{ marginTop: 30 }}>NF</Title>
            <Description>{orderBuy.nf ? orderBuy.nf : "Sem NF"}</Description>
          </Grid>
        </Grid>
      </MainModal>
    </>
  );
}
