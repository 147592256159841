import styled from "styled-components";

export const Area = styled.div` 
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding: 1em;
    box-sizing: border-box;

    @media (max-width: 468px){
        margin-left: 0.8em;
        margin-right: 0.8em;
    }
`;