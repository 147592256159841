import { Grid } from "@mui/material";
import styled from "styled-components";

export const Step = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #eaeaea;
  border-top: 1px solid #eaeaea;
  justify-content: space-between;
  padding: 1em 0;

  :first-child {
    border-top: 0 !important;
  }

  :last-child {
    border-bottom: 0 !important;
  }

  h4 {
    align-self: center;
    font-weight: 700;
    font-size: 14px;
    color: #ababb6;
  }

  h3 {
    align-self: center;
    font-weight: 700;
    font-size: 14px;
    color: black;
  }

  span {
    display: block;
    background: ${(props) => (props.ativo ? "#3FC2DA" : "#ABABB6")};
    border-radius: 34px;
    padding: 0.5em 1em;
    border: 0;
    align-self: center;
    color: white;
  }

  button {
    display: flex;
    align-self: center;
    background: #da45f8;
    border-radius: 34px;
    padding: 0.5em 1em;
    border: 0;
    align-self: center;
    color: white;

    span {
      padding: 0;
      font-size: 14px;
      background: transparent;
      padding-right: 1em;
      font-weight: 700;
    }

    svg {
      font-size: 20px;
    }
  }

  button:hover {
    opacity: 0.6;
  }
`;

export const GridBorder = styled(Grid)`
  position: relative;
  top: 1em;
  left: 1em;
  padding: 1em 1em;
  border: 1px solid #cdcdd2;
  border-radius: 50px;

  :before {
    content: "Vigência";
    position: absolute;
    bottom: 5.8em;
    right: 42%;
    background: white;
    padding: 0.1em 1em;
    font-weight: 700;
    font-size: 13px;
    color: #313131;
  }
`;

export const ButtonArea = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 2em;

  button {
    :first-child {
      display: flex;
      align-self: center;
      align-items: center;
      padding: 8px 16px;
      border-radius: 22px;
      background: white;
      border: 1px solid #606060;
      font-weight: 400;
      font-size: 1rem;
      line-height: 25px;
      color: #606060;

      span {
        display: flex;
        padding-left: 8px;
      }

      svg {
        font-size: 20px;
      }
    }

    :hover {
      opacity: 0.6;
    }
  }
`;
