import styled from "styled-components";
import { Select, TextField, Button } from "@mui/material";

export const SelectInput = styled(Select)`
  font-size: 14px;

  fieldset {
    border-radius: 55px;
  }

  em {
    color: #00000061 !important;
    font-weight: 300 !important;
    font-style: normal !important;
  }
`;

export const Input = styled(TextField)`
  fieldset {
    border-radius: 55px !important;
  }

  input {
    font-size: 14px;
  }
`;

export const Btn = styled(Button)`
  border-radius: 22px !important;
  display: flex;
  width: max-content !important;
  min-width: 100% !important;
  text-transform: none !important;

  span {
    padding-right: 0.5em;
    text-transform: none;
  }

  :hover {
    opacity: 0.6;
  }
`;

export const Text = styled.p`
  font-weight: 700;
  font-size: 14px;
  color: #000000;
  margin: 0;
  padding-bottom: 0.7em;
`;

export const Bold = styled.h3`
  font-weight: 700;
  font-size: 32px;
  color: #000000;
  margin: 0;

  span {
    padding-left: 0.3em;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
  }
`;

export const Badge = styled.span`
  background-color: #cce6ff;
  display: block;
  color: #0080ff;
  width: max-content;
  padding: 0.4em 0.8em;
  border-radius: 22px;
`;

export const AreaFilters = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 15px;
  width: 100%;

  .inputDiv {
    min-width: 100px;
    max-width: 100%;
  }

  @media (max-width: 1000px) and (min-width: 468px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 15px;

    .lastDivFilter {
      margin-top: 0.5em;
    }
  }

  @media (max-width: 468px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 12px;

    .lastDivFilter {
      margin-top: 1em;
    }
  }
`;

export const BtnAction = styled.span`
  background-color: #da45f8;
  color: white;
  border-radius: 50px;
  padding: 0.5em 1em;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-flex;
  justify-content: center;
  width: max-content;
  font-size: 13px;

  :hover {
    opacity: 0.6;
  }

  :active {
    outline: 1px solid lightgrey;
  }
`;
