import styled from "styled-components";

export const Title = styled.h2`
  margin: 0;

  b {
    color: var(--main-color);
  }
`;

export const ModalButtonArea = styled.div`
  gap: 10px;
  display: flex;
  justify-content: flex-end;
  margin-top: 1.7em;

  button {
    background: grey;
    border: 0;
    color: white;
    font-size: 14px;
    border-radius: 6px;
    padding: 0.8em 1.6em;

    :hover {
      opacity: 0.6;
    }

    :active {
      outline: 3px solid lightgrey;
    }

    :first-child {
      border: 1px solid black;
      background-color: transparent;
      color: black;
      display: flex;
      align-items: center;
      gap: 6px;
      margin-right: auto;
    }

    :nth-child(2) {
      background: var(--main-color);
    }
  }
`;
