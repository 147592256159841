import styled from "styled-components";

export const Background = styled.main`
  background: url("images/backgroundLogin.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 468px) {
    padding: 0 1em;
    overflow: hidden;
  }
`;

export const Card = styled.div`
  background: white;
  padding: 2em 1.5em;
  border-radius: 6px;

  button {
    background: var(--main-color);
    color: white;
    font-weight: 500;
    font-size: 14px;
    border-radius: 52px;
    border: 0;
    padding: 0.8em 1.5em;
    display: flex;
    text-transform: none;
  }

  button:hover {
    background: var(--main-color);
    opacity: 0.6 !important;
  }

  p {
    margin: 0;
    color: #da45f8;
    text-decoration: underline;
    text-underline-offset: 5px;
    cursor: pointer;
    padding-top: 1em;
  }

  p:hover {
    opacity: 0.6;
  }

  @media (max-width: 468px) {
    width: 100%;
  }
`;

export const Notication = styled.div`
  background-color: #3fc2da;
  border-radius: 6px;
  margin-bottom: 2em;
  padding: 1em 0;

  svg {
    color: white;
    font-size: 50px;
  }

  h5 {
    padding-top: 0.7em;
    margin: 0;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #ffffff;
  }

  p {
    padding-top: 0.5em !important;
    margin: 0;
    text-decoration: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #ffffff;
    cursor: auto;
  }

  p:hover {
    opacity: 1;
  }
`;
