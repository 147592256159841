import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import CachedIcon from "@mui/icons-material/Cached";
import { Fab, Pagination } from "@mui/material";

import Card from "../../components/Card";
import MainTemplate from "../../components/MainTemplate";
import { Footer, BtnAction, ModalButtonArea } from "./styled";
import Table from "../../components/Table";
import MainModal from "../../components/MainModal";
import api from "../../services/api";
import { loadingFullActive } from "../../store/modules/loadingFull/action";
import { snackbarActive } from "../../store/modules/snackbar/action";

export default function PLD() {
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [contentTable, setContentTable] = useState([]);
  const [open, setOpen] = useState(false);
  const [pldId, setPldId] = useState("");
  const [loading, setLoading] = useState(false);

  const headerTable = ["ID", "Novo Mês", "Preço", "Submercado", "Ações"];

  const openModal = (id) => {
    setOpen(true);
    setPldId(id);
  };

  const getDataApiPlds = async (activePage) => {
    setLoading(true);

    await api
      .get(`/pld`, { params: { page: activePage || page } })
      .then((response) => {
        setLastPage(response.data.last_page);

        let list = response.data.data;

        for (let i = 0; i < list.length; i++) {
          if (list[i]["novo"]) {
            list[i]["novo"] = (
              <BtnAction
                onClick={() => {
                  openModal(list[i].id);
                }}
              >
                {" "}
                Recalcular{" "}
              </BtnAction>
            );
          } else {
            list[i]["novo"] = "";
          }
        }

        setContentTable(list);
      })
      .catch((error) => {
        dispatch(
          snackbarActive({
            type: "error",
            message:
              error?.response?.data?.message || "Tente novamente mais tarde!",
          })
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const apiRecalcPld = async () => {
    setOpen(false);
    dispatch(loadingFullActive(true));

    await api
      .post(`/pld/${pldId}/calc`, null)
      .then((response) => {
        dispatch(
          snackbarActive({
            type: "success",
            message: response?.data?.message || "PLD recalculado com sucesso!",
          })
        );

        getDataApiPlds(page);
      })
      .catch((error) => {
        dispatch(
          snackbarActive({
            type: "error",
            message:
              error?.response?.data?.message || "Tente novamente mais tarde!",
          })
        );
      })
      .finally(() => {
        dispatch(loadingFullActive(false));
      });
  };

  const handlePagination = (page) => {
    setPage(page);
    getDataApiPlds(page);
  };

  const apiReloadPld = async () => {
    await api.get(`/pld/excel`);
    getDataApiPlds();
  };

  useEffect(() => {
    getDataApiPlds();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MainTemplate title="PLDs">
        <Card style={{ padding: 0, marginBottom: 90 }}>
          <Table header={headerTable} loading={loading}>
            {contentTable?.length > 0 &&
              contentTable?.map((item) => (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.novo_mes}</td>
                  <td>R$ {item.preco}</td>
                  <td>{item.submercado}</td>
                  <td>{item.novo}</td>
                </tr>
              ))}
          </Table>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 10,
              paddingBottom: 20,
            }}
          >
            <Pagination
              count={lastPage}
              page={page}
              onChange={(event, value) => handlePagination(value)}
            />
          </div>
        </Card>

        <Footer>
          <Fab
            onClick={() => apiReloadPld()}
            sx={{
              textTransform: "none",
              backgroundColor: "#DA45F8",
              color: "white",
            }}
            variant="extended"
            color="primary"
            aria-label="add"
          >
            Atualizar PLD
            <CachedIcon sx={{ paddingLeft: 1 }} />
          </Fab>
        </Footer>

        <MainModal open={open} setOpen={setOpen}>
          <h2 style={{ margin: 0 }}>Confirmação</h2>

          <p>Deseja realmente recalcular esse PLD?</p>

          <ModalButtonArea>
            <button onClick={() => apiRecalcPld()}>Recalcular</button>
            <button onClick={() => setOpen(false)}>Cancelar</button>
          </ModalButtonArea>
        </MainModal>
      </MainTemplate>
    </>
  );
}
