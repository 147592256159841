import { InputLabel } from "@mui/material";

export default function Label({ sx, text }) {
  return (
    <>
      <InputLabel
        sx={{
          ...sx,
          paddingBottom: ".1rem",
          fontWeight: 700,
          fontSize: "13px",
          color: "#313131",
        }}
      >
        {text}
      </InputLabel>
    </>
  );
}
