import { useEffect, useState } from "react";
import { Grid, CircularProgress, Checkbox, Fab } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import Pagination from "@mui/material/Pagination";
import { useDispatch, useSelector } from "react-redux";

import MainModal from "../../components/MainModal";
import api from "../../services/api";
import {
  Btn,
  Text,
  Bold,
  ModalButtonArea,
  BtnAction,
  Badge,
  Footer,
} from "./styled";
import ModalEditContract from "./components/ModalEditContract";
import ModalOrderBuy from "./components/ModalOrderBuy";
import Card from "../../components/Card";
import MainTemplate from "../../components/MainTemplate";
import Table from "../../components/Table";
import AreaFilters from "./components/AreaFilters";
import { loadingFullActive } from "../../store/modules/loadingFull/action";
import { snackbarActive } from "../../store/modules/snackbar/action";

export default function Dashboard() {
  const dispatch = useDispatch();

  const [contentSelectTipoOperacao, setContentSelectTipoOperacao] = useState(
    []
  );
  const [contentSelectRazaoNegocio, setContentSelectRazaoNegocio] = useState(
    []
  );

  const [idSelectProtheus, setIdSelectProtheus] = useState("");
  const [openModalProtheus, setOpenModalProtheus] = useState(false);

  const [aproveContractData, setAproveContractData] = useState({
    id: "",
    codeIntern: "",
  });

  const [page, setPage] = useState(1);

  const [contentTable, setContentTable] = useState([]);
  const [dataContractPagination, setDataContractPagination] = useState({});
  const userData = useSelector((state) => state.users);
  const [openModal, setOpenModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [loading, setLoading] = useState({
    approved: true,
    contracted: true,
    energy: true,
    contract: true,
    sixMonth: true,
    activitys: true,
    register: true,
    found: true,
    table: true,
  });

  const [filter, setFilter] = useState({
    startData: "",
    finalData: "",
    pontaA: null,
    pontaB: null,
    contract: "",
    energy: "",
    tranche: "",
    code: "",
    internoExterno: "",
    tipoOperacao: "",
    tipoFonte: "",
    razaoNegocio: "",
    submercado: "",
    status: "",
    titulo: "",
  });

  const [selectedsIdsProtheus, setSelectedsIdsProtheus] = useState([]);
  const [isModalProtheusOpen, setIsModalProtheusOpen] = useState(false);

  const [isModalOrderBuyOpen, setIsModalOrderBuyOpen] = useState(false);

  const [selectedContractId, setSelectedContractId] = useState("");

  const headerTable = [
    "Envia Protheus",
    "Interno/Externo",
    "Código interno",
    "Título",
    "Razão do negócio",
    "Tipo de operação",
    "Ponta A",
    "Fato do contrato",
    "Ponta B",
    "Tipo da fonte",
    "Volume MWh",
    "Volume MWm",
    "Submercado",
    "Faturamento",
    "Preço energia inicial",
    "Preço energia atual",
    "Spread",
    "Inicio suprimento",
    "Fim suprimento",
    "Status",
    "Ações",
  ];

  const handleAddOrRemoveIdProtheus = (objectContract, action) => {
    if (action) {
      setSelectedsIdsProtheus((prev) => [...prev, objectContract]);
      return;
    }

    const newList = selectedsIdsProtheus.filter((item) => {
      return item.id !== objectContract.id;
    });

    setSelectedsIdsProtheus([...newList]);
  };

  const handleOpenModalProtheus = (id) => {
    setIdSelectProtheus(id);
    setOpenModalProtheus(true);
  };

  const apiProtheus = async () => {
    dispatch(loadingFullActive(true));
    setOpenModalProtheus(false);

    await api
      .post(`/contracts/${idSelectProtheus}/protheus`, null)
      .then((response) => {
        dispatch(
          snackbarActive({
            type: "success",
            message:
              response?.data?.message ||
              "Contrato enviado para o Protheus com sucesso!",
          })
        );

        getDataApiContracts(page);
        setSelectedsIdsProtheus([]);
      })
      .catch((error) => {
        dispatch(
          snackbarActive({
            type: "error",
            message:
              error?.response?.data?.message || "Tente novamente mais tarde!",
          })
        );
      })
      .finally(() => {
        dispatch(loadingFullActive(false));
      });
  };

  const apiArrayProtheus = async () => {
    dispatch(loadingFullActive(true));
    setIsModalProtheusOpen(false);

    const arrayIdsContracts = selectedsIdsProtheus.map((item) => {
      return item.id;
    });

    await api
      .post(`/contracts/protheus/batch`, { contracts_ids: arrayIdsContracts })
      .then((response) => {
        dispatch(
          snackbarActive({
            type: "success",
            message:
              response?.data?.message ||
              "Contratos enviados para o Protheus com sucesso!",
          })
        );

        getDataApiContracts(page);
        setSelectedsIdsProtheus([]);
      })
      .catch((error) => {
        dispatch(
          snackbarActive({
            type: "error",
            message:
              error?.response?.data?.message || "Tente novamente mais tarde!",
          })
        );
      })
      .finally(() => {
        dispatch(loadingFullActive(false));
      });
  };

  const openModalAproveContract = (idContract, idCodInterno) => {
    setAproveContractData({
      id: idContract,
      codeIntern: idCodInterno,
    });

    setOpenModal(true);
  };

  const apiAproveContract = async () => {
    setOpenModal(false);
    dispatch(loadingFullActive(true));

    await api
      .post(`/contracts/${aproveContractData.id}/aprove`, {
        cod_interno: aproveContractData.codeIntern,
      })
      .then((response) => {
        dispatch(
          snackbarActive({
            type: "success",
            message:
              response?.data?.message || "Contrato aprovado com sucesso!",
          })
        );
      })
      .catch((error) => {
        dispatch(
          snackbarActive({
            type: "error",
            message:
              error?.response?.data?.message || "Tente novamente mais tarde!",
          })
        );
      })
      .finally(() => {
        setAproveContractData({
          id: "",
          codeIntern: "",
        });
        getDataApiContracts(page);

        dispatch(loadingFullActive(false));
      });
  };

  const getDataApiContracts = async (activePage) => {
    setLoadingTable(true);

    await api
      .get(`/dashboard/contracts`, {
        params: {
          page: activePage || page,
          "inicio-vigencia": filter.startData || null,
          "ponta-a": filter.pontaA?.value || null,
          "fato-contrato": filter.contract || null,
          "fonte-energia": filter.energy || null,
          "final-vigencia": filter.finalData || null,
          "codigo-contrato": filter.code || null,
          "tipo-operacao": filter.tipoOperacao || null,
          submercado: filter.submercado || null,
          tranche: filter.tranche || null,
          "ponta-b": filter.pontaB?.value || null,
          "interno-externo": filter.internoExterno || null,
          "razao-negocio": filter.razaoNegocio || null,
          status: filter.status || null,
          titulo: filter.titulo || null,
        },
      })
      .then((response) => {
        setDataContractPagination(response.data);

        let list = response.data.items.data;
        let newList = [];

        for (let i = 0; i < list.length; i++) {
          let contract = {
            id: list[i]["id"],
            enviaProtheus: list[i]["envia_protheus"],
            titulo: list[i]["Título"],
            internoExterno: list[i]["Interno/Externo"],
            codigoInterno: list[i]["Código interno"],
            razaoNegocio: list[i]["Razão do negócio"],
            tipoOperacao: list[i]["Tipo de operação"],
            pontaA: list[i]["Ponta A"],
            fatoContrato: list[i]["Fato do Contrato"],
            pontaB: list[i]["Ponta B"],
            tipoFonte: list[i]["Tipo de fonte"],
            volumeMwh: list[i]["Volume MWh"],
            volumeMwm: list[i]["Volume MWm"],
            submercado: list[i]["Submercado"],
            faturamento: Number(list[i]["Faturamento"]).toLocaleString(
              "pt-br",
              { style: "currency", currency: "BRL" }
            ),
            precoEnergiaInicial: Number(
              list[i]["Preço da energia inicial"]
            ).toLocaleString("pt-br", { style: "currency", currency: "BRL" }),
            precoEnergia: Number(list[i]["Preço da energia"]).toLocaleString(
              "pt-br",
              { style: "currency", currency: "BRL" }
            ),
            spread:
              Number(list[i]["Spread"]).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              }) || 0,
            inicioSuprimento: list[i]["Inicio suprimento"],
            fimSuprimento: list[i]["Fim suprimento"],
            status: <Badge>{list[i]["Status"]}</Badge>,
          };

          if (list[i]["Status"] === "Em análise") {
            contract.acoes = (
              <div style={{ display: "flex" }}>
                {Number(list[i]["criado_por"]) !== Number(userData.id) && (
                  <BtnAction
                    onClick={() =>
                      openModalAproveContract(
                        list[i].id,
                        list[i]["Código interno"]
                      )
                    }
                  >
                    Aprovar contrato
                  </BtnAction>
                )}

                <BtnAction
                  style={{ marginLeft: 10, background: "#B0A5F3" }}
                  onClick={() => {
                    setOpenEditModal(true);
                    setSelectedContractId(list[i].id);
                  }}
                >
                  Editar contrato
                </BtnAction>

                <BtnAction
                  style={{ marginLeft: 10, background: "#B0A5F3" }}
                  onClick={() => {
                    setSelectedContractId(list[i].id);
                    setIsModalOrderBuyOpen(true);
                  }}
                >
                  Histórico
                </BtnAction>
              </div>
            );
          } else {
            contract.acoes = (
              <div style={{ display: "flex" }}>
                <BtnAction
                  style={{ marginLeft: 10, background: "#B0A5F3" }}
                  onClick={() => {
                    setOpenEditModal(true);
                    setSelectedContractId(list[i].id);
                  }}
                >
                  Editar contrato
                </BtnAction>

                {Number(list[i]["envia_protheus"]) === 1 && (
                  <BtnAction
                    style={{ marginLeft: 10, background: "#000044" }}
                    onClick={() => handleOpenModalProtheus(list[i].id)}
                  >
                    Protheus
                  </BtnAction>
                )}

                <BtnAction
                  style={{ marginLeft: 10, background: "#B0A5F3" }}
                  onClick={() => {
                    setSelectedContractId(list[i].id);
                    setIsModalOrderBuyOpen(true);
                  }}
                >
                  Histórico
                </BtnAction>
              </div>
            );
          }

          newList.push(contract);
        }

        setContentTable(newList);
      })
      .catch((error) => {
        dispatch(
          snackbarActive({
            type: "error",
            message:
              error?.response?.data?.message || "Tente novamente mais tarde!",
          })
        );
      })
      .finally(() => {
        setLoadingTable(false);
      });
  };

  const getExportLink = async () => {
    dispatch(loadingFullActive(true));

    await api
      .get("/contracts/export", {
        params: {
          "inicio-vigencia": filter.startData || null,
          "ponta-a": filter.pontaA || null,
          "fato-contrato": filter.contract || null,
          "fonte-energia": filter.energy || null,
          "final-vigencia": filter.finalData || null,
          "codigo-contrato": filter.code || null,
          "tipo-operacao": filter.tipoOperacao || null,
          submercado: filter.submercado || null,
          tranche: filter.tranche || null,
          "ponta-b": filter.pontaB || null,
          "interno-externo": filter.internoExterno || null,
          "razao-negocio": filter.razaoNegocio || null,
          status: filter.status || null,
          titulo: filter.titulo || null,
        },
      })
      .then(({ data: { file_url } }) => {
        window.open(file_url, "_blank").focus();
      })
      .finally(() => {
        dispatch(loadingFullActive(false));
      });
  };

  const handlePagination = (activePage) => {
    setPage(activePage);
    getDataApiContracts(activePage);
  };

  const loadingBlock = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <CircularProgress sx={{ color: "var(--main-color)" }} />
      </div>
    );
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading({
        approved: false,
        contracted: false,
        energy: false,
        contract: false,
        sixMonth: false,
        activitys: false,
        register: false,
        found: false,
        table: false,
      });
    }, 1000);

    getDataApiContracts();
  }, []);

  useEffect(() => {
    handlePagination(1);
  }, [filter]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MainTemplate title="Dashboard">
        <Grid pt={1} container alignItems="stretch" spacing={1}>
          <AreaFilters
            filter={filter}
            contentSelectRazaoNegocio={contentSelectRazaoNegocio}
            contentSelectTipoOperacao={contentSelectTipoOperacao}
            getDataApiContracts={getDataApiContracts}
            setContentSelectRazaoNegocio={setContentSelectRazaoNegocio}
            setContentSelectTipoOperacao={setContentSelectTipoOperacao}
            setFilter={setFilter}
            setPage={setPage}
          />

          <Grid item md={2} sm={3} xs={12}>
            <Card>
              {loading.register ? (
                loadingBlock()
              ) : (
                <>
                  <Text>Total de registros</Text>

                  <Bold>
                    {dataContractPagination?.total}
                    <span style={{ fontSize: "13px" }}>contratos</span>
                  </Bold>
                </>
              )}
            </Card>

            <Card style={{ marginTop: "8px" }}>
              {loading.found ? (
                loadingBlock()
              ) : (
                <>
                  <Text>Encontrados</Text>
                  <Bold>
                    {dataContractPagination?.on_search}
                    <span style={{ fontSize: "13px" }}>contratos</span>
                  </Bold>
                </>
              )}
            </Card>

            <Card style={{ marginTop: "8px" }}>
              {loading.found ? (
                loadingBlock()
              ) : (
                <div style={{ textAlign: "center" }}>
                  <Btn
                    fullWidth
                    style={{
                      color: "white",
                      backgroundColor: "var(--main-color)",
                    }}
                    onClick={getExportLink}
                  >
                    Exportar XLSX
                  </Btn>
                </div>
              )}
            </Card>
          </Grid>
        </Grid>

        <Grid pt={1} container spacing={1}>
          <Grid item md={12} xs={12}>
            <Card style={{ padding: 0 }}>
              <Table
                header={headerTable}
                loading={loadingTable}
                scrollTopActive={true}
              >
                {contentTable?.length > 0 &&
                  contentTable?.map((item) => (
                    <tr key={item.id}>
                      <td style={{ textAlign: "left" }}>
                        {item.enviaProtheus === 1 && (
                          <Checkbox
                            sx={{
                              "&.Mui-checked": {
                                color: "var(--main-color)",
                              },
                            }}
                            checked={
                              !!selectedsIdsProtheus.some(
                                (e) => e.id === item.id
                              )
                            }
                            onChange={(e) =>
                              handleAddOrRemoveIdProtheus(
                                {
                                  id: item.id,
                                  label: `${item.codigoInterno} - ${item.inicioSuprimento} | ${item.fimSuprimento}`,
                                },
                                e.target.checked
                              )
                            }
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        )}
                      </td>
                      <td>{item.internoExterno}</td>
                      <td>{item.codigoInterno}</td>
                      <td>{item.titulo}</td>
                      <td>{item.razaoNegocio}</td>
                      <td>{item.tipoOperacao}</td>
                      <td>{item.pontaA}</td>
                      <td>{item.fatoContrato}</td>
                      <td>{item.pontaB}</td>
                      <td>{item.tipoFonte}</td>
                      <td>{item.volumeMwh}</td>
                      <td>{item.volumeMwm}</td>
                      <td>{item.submercado}</td>
                      <td>{item.faturamento}</td>
                      <td>{item.precoEnergiaInicial}</td>
                      <td>{item.precoEnergia}</td>
                      <td>{item.spread}</td>
                      <td>{item.inicioSuprimento}</td>
                      <td>{item.fimSuprimento}</td>
                      <td>{item.status}</td>
                      <td>{item.acoes}</td>
                    </tr>
                  ))}
              </Table>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 10,
                  paddingBottom: 20,
                }}
              >
                <Pagination
                  count={dataContractPagination.items?.last_page}
                  page={page}
                  onChange={(event, value) => handlePagination(value)}
                />
              </div>
            </Card>
          </Grid>
        </Grid>
      </MainTemplate>

      <MainModal width={450} open={openModal} setOpen={setOpenModal}>
        <h2 style={{ margin: 0 }}>Confirmação</h2>

        <p>Deseja realmente aprovar esse contrato?</p>

        <ModalButtonArea>
          <button onClick={() => apiAproveContract()}>Aprovar</button>
          <button onClick={() => setOpenModal(false)}>Cancelar</button>
        </ModalButtonArea>
      </MainModal>

      <MainModal
        width={450}
        open={openModalProtheus}
        setOpen={setOpenModalProtheus}
      >
        <h2 style={{ margin: 0 }}>Confirmação</h2>

        <p>Deseja realmente enviar esse contrato para o Protheus?</p>

        <ModalButtonArea>
          <button onClick={() => apiProtheus()}>Enviar</button>
          <button onClick={() => setOpenModalProtheus(false)}>Cancelar</button>
        </ModalButtonArea>
      </MainModal>

      <MainModal
        width={450}
        open={isModalProtheusOpen}
        setOpen={setIsModalProtheusOpen}
      >
        <h2 style={{ margin: 0 }}>Confirmação</h2>

        <p>Deseja realmente enviar esses contratos para o Protheus?</p>

        <div style={{ overflowX: "scroll", maxHeight: "300px" }}>
          {selectedsIdsProtheus.map((item) => (
            <p
              key={item.id}
              style={{
                fontSize: "14px",
                background: "#c6c6c670",
                margin: ".5em 0",
                borderRadius: "50px",
                padding: ".5em 1em",
                width: "fit-content",
              }}
            >
              {item.label}
            </p>
          ))}
        </div>

        <ModalButtonArea>
          <button onClick={() => apiArrayProtheus()}>Enviar</button>

          <button onClick={() => setIsModalProtheusOpen(false)}>
            Cancelar
          </button>
        </ModalButtonArea>
      </MainModal>

      <ModalEditContract
        selects={{ contentSelectTipoOperacao, contentSelectRazaoNegocio }}
        open={openEditModal}
        setOpen={setOpenEditModal}
        contractId={selectedContractId}
        getDataApiContracts={getDataApiContracts}
      />

      {selectedsIdsProtheus.length > 0 && (
        <Footer>
          <Fab
            onClick={() => setIsModalProtheusOpen(true)}
            sx={{
              textTransform: "none",
              backgroundColor: "#DA45F8",
              color: "white",
            }}
            variant="extended"
            color="primary"
            aria-label="add"
          >
            Enviar Protheus
            <CheckIcon sx={{ paddingLeft: 1 }} />
          </Fab>
        </Footer>
      )}

      <ModalOrderBuy
        contractId={selectedContractId}
        open={isModalOrderBuyOpen}
        setOpen={setIsModalOrderBuyOpen}
      />
    </>
  );
}
